.sort {
  margin: 20px 0;

  .sort__block {
    display: flex;
  }

  .sort__element {
    flex-basis: 180px;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }

    .jq-selectbox__select {
      width: 100%;
    }

  }

  .sort__element--big {
    flex-basis: 220px;
  }

  .sort__maps {
    flex-basis: 250px;
    margin-left: 20px;
    display: flex;
    align-items: center;

    a {
      width: 125px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      color: $white;
      font-size: 1.125rem;
      line-height: 1.5rem;
      box-shadow: inset 1px 1px 1px rgba(0,0,0,.28);
      background-color: $green;
      background-image: linear-gradient(to top, rgba(92,184,92,.52), rgba(68,134,68,.52));
      padding: 4.5px 0;

      &:hover {
        background-color: darken($green, 10%);
      }

    }
    
    .sort__active {
      box-shadow: 1px 2px 2px rgba(0,0,0,.35);
      background-color: $blue;
      padding: 6px 0;
      background-image: none;

      &:hover {
        background-color: $blue;
      }

    }

  }

}

@media (max-width: 680px) {

  .sort--big {

    .sort__block {
      flex-direction: column;

      .sort__element {
        flex-basis: auto;
        margin: 10px 0;
      }

    }

  }

}

@media (max-width: 480px) {

  .sort {

    .sort__block {
      flex-direction: column;
    }

    .sort__element {
      flex-basis: auto;
      margin: 10px 0;
    }

  }

}