.front-page {
  background-color: $gray;
  padding: 25px 0;

  .front-page__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .front-page__left {
      flex-basis: 74%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .front-page__right {
      flex-basis: calc(100% - 74% - 30px);
    }

  }

  .front-page__buttons {
    display: flex;
    margin-top: 0;
    justify-content: space-between;
  }


  .front-page__socials {
    margin-top: 5px;
  }

  .front-page__press {
    flex-basis: 55%;
    margin-right: 20px;

    a {
      @include btn();
    }

  }

  .front-page__tables {
    display: flex;
    justify-content: space-between;
    margin: 35px 0;

    .front-page__left {
      flex-basis: 48.6%;
    }

    .front-page__right {
      flex-basis: 48.6%;
    }

  }

  .front-page__new-blocks {
    display: flex;
    justify-content: space-between;
    margin: 35px 0;

    .front-page__left {
      flex-basis: 65.7%;
      margin-right: 30px;
    }

    .front-page__right {
      flex-basis: 350px;
      flex-shrink: 0;
    }

  }

  .front-page__live {

    .front-page__left {
      flex-basis: 48.6%;
    }

    .front-page__right {
      flex-basis: 48.6%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

  }

}

@media (max-width: 860px) {

  .front-page {

    .front-page__top {
      
      .front-page__left {
        flex-basis: 100%;
        margin-right: 0;
      }
      
      .front-page__right {
        flex-basis: 100%;
        margin-top: 35px;
      }
      
    }

    .front-page__live {

      .front-page__left {
        order: 2;
      }

      .front-page__right {
        order: 1;
        margin-bottom: 20px;
        justify-content: flex-start;
      }

    }

  }
}

@media (max-width: 768px) {

  .front-page {

    .front-page__tables {
      flex-wrap: wrap;
      
      .front-page__left {
        flex-basis: 100%;
        margin-bottom: 35px;
      }
      
      .front-page__right {
        flex-basis: 100%;
      }

    }


    .front-page__new-blocks {
      flex-wrap: wrap;

      .front-page__left {
        flex-basis: 100%;
        margin-right: 0;
        margin-bottom: 25px;
      }

      .front-page__right {
        flex-basis: 100%;
        flex-shrink: 1;
      }

    }

  }


}

@media (max-width: 580px) {

  .front-page {

    .front-page__buttons {
      flex-wrap: wrap;
    }

    .front-page__press {
      margin-right: 0;
      flex-basis: 100%;
    }

    .front-page__news {
      height: auto;
    }

  }

}