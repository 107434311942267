.press {
  background: $white;
  padding: 5px 30px 20px 30px;
}

@media (max-width: 768px) {

  .press {
    padding-left: 20px;
    padding-right: 20px;
  }

}