.results {

  .breadcrumbs {
    margin-top: -5px;
  }

  .results__block {
    margin: 25px 0;
  }

  .results__element {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 15px 25px 15px 30px;
    background-color: $white;

    &:nth-child(odd) {
      background-color: #f5f5f5;
    }

    .results__date {
      flex-basis: 185px;
      flex-shrink: 0;

      p {
        font-size: 1.125rem;
        line-height: 1.5625rem;
        color: $blue;
      }

    }

    .results__link {
      flex-basis: calc(100% - 185px);
      position: relative;

      a {
        font-size: 1.25rem;
        line-height: 1.875rem;
        text-decoration: none;
        color: $black;
        padding-right: 60px;
        display: inline-block;

        &:hover {
          color: $sub-menu-blue;
        }

        span {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

      }

    }

  }

  .results__btn {
    margin: 0 0 20px;

    a {

      @include btn() {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 400;
        padding: 11px 0;
      };

      .results__arr {
        transform: rotate(90deg);
        margin-left: 5px;
      }

    }

  }

}

@media (max-width: 860px) {

  .results {

    .results__element {
      padding: 15px;

      .results__link {
        flex-basis: calc(100% - 140px);

        a {
          padding-right: 35px;
        }

      }

      .results__date {
        flex-basis: 140px;
      }

    }

  }

}

@media (max-width: 480px) {

  .results {

    .results__element {
      flex-direction: column;

      .results__link {
        flex-basis: auto;
      }

      .results__date {
        flex-basis: auto;
      }

    }

  }

}