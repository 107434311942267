$white: #fff;
$black: #000;

$blue: #428bca;
$light-blue: #4ca0d2;

$sub-menu-blue: #94cde9;

$red: #d92c22;
$gray: #e3e3e3;

$gold: #dab509;
$silver: #a1a1a1;
$bronze: #ae7058;
$green: #61bf1a;
