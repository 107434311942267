@charset "UTF-8";
/**
 * Сброс стилей
 **/
@import url("https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:400,500|Open+Sans:400,600,700&subset=cyrillic");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

input {
  outline: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

/**
 * Основные стили
 **/
html {
  font-size: 16px;
  color: #000; }

@media (max-width: 880px) {
  html {
    font-size: 14px; } }

body {
  font-family: 'Open Sans', sans-serif;
  hyphens: auto; }

.container {
  min-width: 320px;
  max-width: 100%;
  padding: 0 0;
  box-sizing: border-box;
  margin: 0 auto; }

.wrap {
  min-width: 320px;
  max-width: 1140px;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto; }

textarea, input {
  font-family: 'Open Sans'; }

@media (min-width: 320px) {
  .fancybox-show-thumbs .fancybox-inner {
    right: 0 !important;
    bottom: 95px; } }

.my-fancybox .fancybox-thumbs {
  top: auto;
  width: auto;
  bottom: 0;
  left: 0;
  right: 0 !important;
  height: 110px;
  padding: 10px 10px 5px 10px;
  box-sizing: border-box;
  background: transparent; }

.my-fancybox .fancybox-thumbs-x {
  overflow-x: hidden; }

.my-fancybox .fancybox-toolbar {
  right: 10px;
  top: 10px; }

.my-fancybox .fancybox-button {
  width: 47px;
  height: 47px;
  box-sizing: border-box;
  padding: 0;
  background-color: transparent; }

.my-fancybox .fancybox-navigation .fancybox-button--arrow_left {
  width: 34px;
  height: 64px;
  padding: 0;
  left: 10px; }

.my-fancybox .fancybox-navigation .fancybox-button--arrow_right {
  width: 34px;
  height: 64px;
  padding: 0;
  right: 10px; }

.my-fancybox .fancybox-thumbs > ul > li {
  width: 160px;
  height: 90px;
  margin: 2px 15px; }

.clear:before, .clear:after {
  content: '';
  display: table;
  clear: both; }

.header {
  background-image: linear-gradient(to top, #428bca, #5bc0de); }
  .header .header__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px; }
  .header .header__map {
    flex-basis: calc(100% - 330px - 375px);
    padding: 0 10px;
    box-sizing: border-box; }
    .header .header__map a {
      font-size: 1.125rem;
      line-height: 1.4rem;
      padding: 3px 0 3px 85px;
      color: #fff;
      font-family: 'Fira Sans Condensed';
      background: url("../img/rodina.png") left center no-repeat;
      background-size: 70px;
      text-decoration: none;
      display: block;
      max-width: 170px;
      margin: 0 auto; }
      .header .header__map a:hover {
        color: #94cde9; }
    .header .header__map span {
      display: block;
      font-size: 0.875rem;
      line-height: 1.25rem; }
  .header .header__supports {
    flex-basis: 375px;
    flex-shrink: 0; }
    .header .header__supports p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: #fff;
      background: url("../img/supports.png") left center no-repeat;
      padding-left: 45px;
      font-family: 'Fira Sans Condensed'; }
      .header .header__supports p span {
        font-size: 1rem;
        line-height: 1.25rem;
        display: block; }
      .header .header__supports p a {
        text-decoration: none;
        color: #fff; }
        .header .header__supports p a:hover {
          color: #94cde9; }
  .header .header__hamburger {
    display: none;
    cursor: pointer;
    position: relative;
    z-index: 12; }
    .header .header__hamburger img, .header .header__hamburger svg {
      display: block;
      width: 28px;
      height: 28px;
      fill: #fff; }
    .header .header__hamburger .header__close {
      display: none; }

@media (max-width: 960px) {
  .header .header__map {
    display: none; } }

@media (max-width: 768px) {
  .header .header__supports {
    display: none; }
  .header .header__hamburger {
    display: block; }
  .header .header__hamburger--active svg {
    display: none; }
  .header .header__hamburger--active .header__close {
    display: block; } }

.logo {
  display: flex;
  align-items: center;
  flex-basis: 330px;
  flex-shrink: 0; }
  .logo > a {
    display: flex;
    align-items: center;
    flex-basis: 330px;
    flex-shrink: 0;
    text-decoration: none; }
  .logo .logo__img a {
    display: block; }
  .logo .logo__text {
    margin-left: 15px; }
    .logo .logo__text p {
      font-size: 1.875rem;
      line-height: 1.6875rem;
      font-family: 'Fira Sans Condensed';
      font-weight: 500;
      color: #fff; }
    .logo .logo__text span {
      font-size: 1.3125rem;
      display: block; }

@media (max-width: 480px) {
  .logo {
    flex-basis: 230px; }
    .logo .logo__img img {
      display: block;
      max-width: 40px; }
    .logo .logo__text {
      margin-right: 0; }
      .logo .logo__text p {
        font-size: 1.57rem;
        line-height: 1.42; }
        .logo .logo__text p span {
          font-size: 1.2rem; } }

.menu {
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
  background-color: #428bca;
  transition-duration: 200ms; }
  .menu .menu__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .menu ul {
    display: flex; }
    .menu ul li {
      margin-right: 6px;
      position: relative;
      padding: 0 10px;
      box-sizing: border-box; }
      .menu ul li:last-child {
        margin-right: 0; }
      .menu ul li:hover {
        background-color: #2f83c2; }
        .menu ul li:hover .menu__children {
          opacity: 1;
          transform: rotateX(0deg); }
    .menu ul .menu__parent a {
      background: url("../img/arrow-bottom.png") right center no-repeat;
      padding-right: 17px; }
    .menu ul .menu__active > a {
      border-bottom: 3px solid #000; }
    .menu ul a {
      text-decoration: none;
      color: #fff;
      font-size: 1.125rem;
      line-height: 1.625rem;
      display: block;
      padding-top: 7px;
      padding-bottom: 7px; }
    .menu ul .menu__children {
      position: absolute;
      left: -10px;
      top: 100%;
      background-color: #4ca0d2;
      max-width: 350px;
      box-sizing: border-box;
      box-shadow: 2px 3px 3px rgba(19, 26, 20, 0.21);
      border-top: 1px solid #000;
      padding: 15px 0;
      display: block;
      min-width: 230px;
      transition: transform .3s ease,opacity .3s ease;
      transform: rotateY(90deg);
      transform-origin: 50% 0 0;
      opacity: 0;
      will-change: opacity,transform;
      z-index: 100; }
      .menu ul .menu__children li {
        margin-right: 0;
        display: block;
        background-color: transparent;
        padding: 0; }
      .menu ul .menu__children a {
        background-image: none;
        line-height: 1.875rem;
        padding: 0 10px 0 20px; }
        .menu ul .menu__children a:hover {
          color: #94cde9;
          background-color: #2f83c2; }
    .menu ul .menu__open {
      width: 20px;
      height: 20px;
      display: none;
      cursor: pointer; }
      .menu ul .menu__open img {
        width: 15px;
        transform: translateY(3px);
        margin-left: 10px; }

@media (max-width: 980px) {
  .menu ul li {
    margin-right: 0px;
    padding: 0 7px; }
  .menu ul a {
    font-size: 1rem; } }

@media (max-width: 880px) {
  .menu ul li:hover .menu__children {
    top: 35px; } }

@media (max-width: 768px) {
  .menu {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #428bca;
    width: 100%;
    padding: 20px 0;
    opacity: 0;
    height: 0;
    z-index: -1; }
    .menu ul {
      display: block; }
      .menu ul li {
        display: block; }
        .menu ul li:hover {
          background-color: transparent; }
      .menu ul a {
        padding-top: 3px;
        padding-bottom: 3px;
        display: inline-block;
        padding-right: 0;
        font-size: 1.15rem;
        line-height: 1.85rem; }
      .menu ul .menu__parent a {
        background: none; }
      .menu ul .menu__active > a {
        border-bottom: none; }
      .menu ul .menu__parent--active .menu__children {
        display: block; }
      .menu ul .menu__open {
        display: inline; }
      .menu ul .menu__children {
        position: static;
        left: auto;
        top: auto;
        transform: rotateX(0deg);
        opacity: 1;
        border-top: none;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        margin-left: 15px;
        display: none; }
        .menu ul .menu__children a {
          padding: 0; }
  .menu--active {
    opacity: 1;
    height: 100%;
    max-height: 400px;
    box-sizing: border-box;
    overflow-y: auto;
    z-index: 11; } }

.search {
  position: relative; }
  .search .search__block {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
  .search .search__input {
    position: absolute;
    left: -140px;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transition-duration: 500ms; }
  .search .search__button {
    width: 48px;
    position: relative; }
  .search input {
    box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.28);
    width: 141px;
    background-color: #fff;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #6a6565;
    padding: 0 0 1px 10px;
    box-sizing: border-box;
    border: 1px solid #9a9a9a; }
  .search button {
    box-shadow: none;
    border: none;
    background: none;
    margin: 0;
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    padding: 4.5px 0;
    cursor: pointer; }
    .search button svg {
      width: 19px;
      height: 19px;
      fill: #fff;
      margin-right: 5px; }
  .search .search__block--active .search__input {
    opacity: 1;
    pointer-events: auto; }
  .search .search__block--active button {
    background: url("../img/button-bg.png") left no-repeat; }

@media (max-width: 880px) {
  .search input {
    padding: 2px 0 3px 10px; } }

@media (max-width: 768px) {
  .search {
    display: none; } }

.front-page {
  background-color: #e3e3e3;
  padding: 25px 0; }
  .front-page .front-page__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .front-page .front-page__top .front-page__left {
      flex-basis: 74%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    .front-page .front-page__top .front-page__right {
      flex-basis: calc(100% - 74% - 30px); }
  .front-page .front-page__buttons {
    display: flex;
    margin-top: 0;
    justify-content: space-between; }
  .front-page .front-page__socials {
    margin-top: 5px; }
  .front-page .front-page__press {
    flex-basis: 55%;
    margin-right: 20px; }
    .front-page .front-page__press a {
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
      background-color: #428bca;
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 1.5625rem;
      text-align: center;
      color: #fff;
      text-decoration: none;
      padding: 16px 0;
      display: block; }
      .front-page .front-page__press a .quote {
        transition-duration: 200ms;
        transition-property: transform; }
      .front-page .front-page__press a:hover {
        background-color: #3071a9;
        box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
        .front-page .front-page__press a:hover .quote {
          transform: translateX(5px); }
  .front-page .front-page__tables {
    display: flex;
    justify-content: space-between;
    margin: 35px 0; }
    .front-page .front-page__tables .front-page__left {
      flex-basis: 48.6%; }
    .front-page .front-page__tables .front-page__right {
      flex-basis: 48.6%; }
  .front-page .front-page__new-blocks {
    display: flex;
    justify-content: space-between;
    margin: 35px 0; }
    .front-page .front-page__new-blocks .front-page__left {
      flex-basis: 65.7%;
      margin-right: 30px; }
    .front-page .front-page__new-blocks .front-page__right {
      flex-basis: 350px;
      flex-shrink: 0; }
  .front-page .front-page__live .front-page__left {
    flex-basis: 48.6%; }
  .front-page .front-page__live .front-page__right {
    flex-basis: 48.6%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

@media (max-width: 860px) {
  .front-page .front-page__top .front-page__left {
    flex-basis: 100%;
    margin-right: 0; }
  .front-page .front-page__top .front-page__right {
    flex-basis: 100%;
    margin-top: 35px; }
  .front-page .front-page__live .front-page__left {
    order: 2; }
  .front-page .front-page__live .front-page__right {
    order: 1;
    margin-bottom: 20px;
    justify-content: flex-start; } }

@media (max-width: 768px) {
  .front-page .front-page__tables {
    flex-wrap: wrap; }
    .front-page .front-page__tables .front-page__left {
      flex-basis: 100%;
      margin-bottom: 35px; }
    .front-page .front-page__tables .front-page__right {
      flex-basis: 100%; }
  .front-page .front-page__new-blocks {
    flex-wrap: wrap; }
    .front-page .front-page__new-blocks .front-page__left {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 25px; }
    .front-page .front-page__new-blocks .front-page__right {
      flex-basis: 100%;
      flex-shrink: 1; } }

@media (max-width: 580px) {
  .front-page .front-page__buttons {
    flex-wrap: wrap; }
  .front-page .front-page__press {
    margin-right: 0;
    flex-basis: 100%; }
  .front-page .front-page__news {
    height: auto; } }

.news-front {
  background-color: #fff;
  padding-bottom: 10px;
  height: 100%; }
  .news-front .news-front__block {
    margin: 17px 0;
    padding: 0 35px; }
    .news-front .news-front__block:nth-child(2) {
      padding-top: 5px; }
    .news-front .news-front__block p {
      color: #8b8a8a;
      font-size: 1rem;
      line-height: 1.5625rem;
      margin: 5px 0; }
      .news-front .news-front__block p a {
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: #000;
        font-weight: 400;
        text-decoration: none;
        display: inline;
        margin-left: 5px; }
        .news-front .news-front__block p a:hover {
          color: #94cde9; }
  .news-front .news-front__block--first {
    padding: 0;
    margin: 0;
    position: relative; }
    .news-front .news-front__block--first > a {
      position: relative;
      display: block;
      overflow: hidden; }
      .news-front .news-front__block--first > a:hover span {
        color: #94cde9; }
      .news-front .news-front__block--first > a:hover img {
        filter: brightness(110%);
        transform: scale(1.1);
        box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
    .news-front .news-front__block--first img {
      display: block;
      max-width: 100%;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
      transition-duration: 500ms;
      transition-property: filter, transform; }
    .news-front .news-front__block--first p {
      color: #fff;
      font-size: 1.125rem;
      line-height: 1.5625rem; }
      .news-front .news-front__block--first p span {
        font-size: 1.375rem;
        line-height: 1.75rem;
        color: #fff;
        font-weight: 600;
        text-decoration: none;
        display: inline;
        margin-left: 5px; }
  .news-front .news-front__text {
    padding: 25px 35px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)); }
  .news-front .news-front__online {
    position: absolute;
    left: -10px;
    top: 30px;
    clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%);
    padding: 5px 0; }
    .news-front .news-front__online a {
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
      background-color: #d92c22;
      width: 270px;
      display: block;
      font-size: 1.25rem;
      line-height: 1.25rem;
      padding: 13px 0;
      color: #fff;
      text-decoration: none;
      font-weight: 600;
      text-align: center; }
      .news-front .news-front__online a:hover {
        box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
    .news-front .news-front__online .quote {
      font-size: 1rem;
      line-height: 1.875rem; }

@media (max-width: 860px) {
  .news-front .news-front__block--first img {
    width: 100%; } }

@media (max-width: 480px) {
  .news-front .news-front__block {
    padding: 0 15px; }
    .news-front .news-front__block p {
      font-size: 1rem;
      line-height: 1.2rem; }
      .news-front .news-front__block p span {
        font-size: 1rem;
        line-height: 1.2rem; }
      .news-front .news-front__block p a {
        font-size: 1rem;
        line-height: 1.2rem; }
  .news-front .news-front__block--first {
    padding: 0; }
    .news-front .news-front__block--first p {
      font-size: 1rem;
      line-height: 1.2rem; }
      .news-front .news-front__block--first p span {
        font-size: 1rem;
        line-height: 1.2rem; }
      .news-front .news-front__block--first p a {
        font-size: 1rem;
        line-height: 1.2rem; }
    .news-front .news-front__block--first .news-front__text {
      bottom: 0;
      padding: 5px 15px; }
  .news-front .news-front__online {
    padding: 10px 0;
    top: 15px; } }

.dzudo-info {
  background-color: #fff;
  height: 100%;
  border-top: 5px solid #428bca;
  padding: 15px;
  text-align: center;
  box-sizing: border-box; }
  .dzudo-info .dzudo-info__title {
    font-size: 1.375rem;
    line-height: 1.5625rem;
    font-weight: 600; }
  .dzudo-info .dzudo-info__element {
    margin: 20px 0; }
    .dzudo-info .dzudo-info__element p {
      font-size: 2.5rem;
      color: #428bca;
      font-weight: 700; }
    .dzudo-info .dzudo-info__element span {
      font-size: 1rem;
      line-height: 1.375rem;
      display: block;
      font-weight: 400;
      color: #000; }
  .dzudo-info .dzudo-info__map {
    text-align: center;
    margin-top: 35px; }
    .dzudo-info .dzudo-info__map img {
      display: block;
      margin: 20px auto; }
    .dzudo-info .dzudo-info__map a {
      text-decoration: none; }
      .dzudo-info .dzudo-info__map a span {
        box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
        background-color: #428bca;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.5625rem;
        text-align: center;
        color: #fff;
        text-decoration: none;
        padding: 16px 0;
        display: block;
        font-size: 1rem;
        line-height: 1.125rem;
        width: 191px;
        padding: 10px 30px;
        margin: 15px auto;
        box-sizing: border-box; }
        .dzudo-info .dzudo-info__map a span .quote {
          transition-duration: 200ms;
          transition-property: transform; }
        .dzudo-info .dzudo-info__map a span:hover {
          background-color: #3071a9;
          box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
          .dzudo-info .dzudo-info__map a span:hover .quote {
            transform: translateX(5px); }

.socials-block {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 25px; }
  .socials-block p {
    color: #8b8a8a;
    font-size: 1.125rem;
    line-height: 1.125rem; }
  .socials-block a {
    margin: 0 10px; }
    .socials-block a img {
      width: 33px;
      display: block; }
    .socials-block a:last-of-type {
      margin-right: 0; }

@media (max-width: 580px) {
  .socials-block {
    flex-basis: 100%;
    justify-content: center; } }

.medal {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }
  .medal .medal__top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 15px 12px 25px; }
  .medal .medal__title {
    color: #000;
    font-size: 1.75rem;
    font-weight: 600; }
  .medal .medal__list {
    margin: 0 10px; }
  .medal .medal__results {
    display: flex;
    flex-basis: 120px;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-shrink: 0; }
    .medal .medal__results div {
      flex-basis: 31px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 50%; }
      .medal .medal__results div:first-child {
        border: 3px solid #dab509; }
      .medal .medal__results div:nth-child(2) {
        border: 3px solid #a1a1a1; }
      .medal .medal__results div:last-child {
        border: 3px solid #ae7058; }
      .medal .medal__results div p {
        line-height: 1.125rem;
        font-size: 1rem;
        font-weight: 600;
        padding: 3.5px 0; }
  .medal .medal__block {
    padding: 13px 15px 13px 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box; }
    .medal .medal__block:nth-child(even) {
      background-color: #f5f5f5; }
  .medal .medal__type {
    flex-basis: calc(100% - 130px); }
    .medal .medal__type p {
      font-size: 1rem;
      line-height: 1.125rem; }
  .medal .medal__results {
    flex-basis: 120px;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-shrink: 0; }
    .medal .medal__results p {
      line-height: 1.125rem;
      font-size: 1rem;
      font-weight: 600;
      padding: 3.5px 0;
      flex-basis: 31px;
      text-align: center; }
  .medal .medal__button a {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block; }
    .medal .medal__button a .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .medal .medal__button a:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .medal .medal__button a:hover .quote {
        transform: translateX(5px); }

@media (max-width: 980px) {
  .medal .medal__title {
    font-size: 1.5rem; }
  .medal .medal__list {
    margin-right: 0; }
  .medal .medal__results {
    margin-left: auto;
    margin-top: 10px; } }

@media (max-width: 880px) {
  .medal .medal__results div p {
    padding: 5px 0; } }

.calendar {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; }
  .calendar .calendar__top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 15px 12px 25px; }
  .calendar .calendar__title {
    color: #000;
    font-size: 1.75rem;
    font-weight: 600; }
  .calendar .calendar__list {
    margin: 0 0 0 10px; }
  .calendar .calendar__block {
    padding: 13px 15px 13px 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center; }
    .calendar .calendar__block:nth-child(even) {
      background-color: #f5f5f5; }
  .calendar .calendar__type p {
    font-size: 1rem;
    line-height: 1.125rem;
    color: #428bca;
    padding-right: 25px;
    position: relative; }
  .calendar .calendar__type a {
    color: #000;
    text-decoration: none; }
    .calendar .calendar__type a:hover {
      color: #94cde9; }
    .calendar .calendar__type a .quote {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
  .calendar .calendar__button a {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block; }
    .calendar .calendar__button a .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .calendar .calendar__button a:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .calendar .calendar__button a:hover .quote {
        transform: translateX(5px); }

@media (max-width: 980px) {
  .calendar .calendar__title {
    font-size: 1.5rem; } }

@media (max-width: 768px) {
  .calendar {
    display: block;
    height: auto; } }

@media (max-width: 480px) {
  .calendar .calendar__list {
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px; } }

.main-events {
  margin: 45px 0; }
  .main-events .main-events__title {
    font-size: 1.75rem;
    line-height: 1.875rem;
    font-weight: 600;
    margin: 15px 0 25px; }
  .main-events .main-events__block {
    display: flex;
    justify-content: space-between; }
  .main-events .main-events__element {
    flex-basis: 31.5%;
    overflow: hidden; }
    .main-events .main-events__element .main-events__img {
      margin-bottom: 10px;
      overflow: hidden; }
    .main-events .main-events__element img {
      display: block;
      width: 100%;
      margin: 0 auto;
      transition-duration: 500ms;
      transition-property: transform, filter; }
    .main-events .main-events__element p {
      color: #428bca; }
      .main-events .main-events__element p span {
        color: #000; }
    .main-events .main-events__element a {
      font-size: 1.125rem;
      line-height: 1.375rem;
      font-weight: 600;
      color: #000;
      text-decoration: none;
      overflow: hidden;
      display: block; }
      .main-events .main-events__element a:hover img {
        filter: brightness(115%);
        transform: scale(1.1); }
      .main-events .main-events__element a:hover p span {
        color: #94cde9; }
    .main-events .main-events__element .main-events__city {
      font-size: 1rem;
      line-height: 1.375rem;
      color: #000;
      margin-top: 6px;
      font-weight: 400; }

@media (max-width: 768px) {
  .main-events .main-events__block {
    flex-direction: column; }
  .main-events .main-events__element {
    flex-basis: auto;
    margin: 15px 0; }
    .main-events .main-events__element img {
      width: 100%; } }

.photo-and-video {
  margin: 45px 0; }
  .photo-and-video .photo-and-video__title {
    font-size: 1.75rem;
    line-height: 1.875rem;
    font-weight: 600;
    margin: 15px 0 25px; }
  .photo-and-video .photo-and-video__block {
    display: flex;
    justify-content: space-between; }
  .photo-and-video .photo-and-video__left {
    flex-basis: 57.2%; }
    .photo-and-video .photo-and-video__left .photo-and-video__element {
      position: relative; }
      .photo-and-video .photo-and-video__left .photo-and-video__element a {
        display: block;
        position: relative;
        overflow: hidden; }
        .photo-and-video .photo-and-video__left .photo-and-video__element a:hover img {
          filter: brightness(115%);
          transform: scale(1.1); }
        .photo-and-video .photo-and-video__left .photo-and-video__element a:hover .photo-and-video__text span {
          color: #94cde9; }
      .photo-and-video .photo-and-video__left .photo-and-video__element .quote {
        font-size: 1.875rem; }
      .photo-and-video .photo-and-video__left .photo-and-video__element .photo-and-video__text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 25px 20px;
        box-sizing: border-box;
        background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0)); }
        .photo-and-video .photo-and-video__left .photo-and-video__element .photo-and-video__text p {
          font-size: 1.125rem;
          line-height: 1.75rem;
          color: #fff; }
          .photo-and-video .photo-and-video__left .photo-and-video__element .photo-and-video__text p span {
            font-size: 1.5rem;
            text-decoration: none;
            color: #fff;
            display: inline-block;
            font-weight: 600; }
          .photo-and-video .photo-and-video__left .photo-and-video__element .photo-and-video__text p .quote {
            font-size: 1.5rem; }
      .photo-and-video .photo-and-video__left .photo-and-video__element img {
        display: block;
        max-width: 100%;
        transition-duration: 500ms;
        transition-property: filter, transform; }
  .photo-and-video .photo-and-video__right {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .photo-and-video .photo-and-video__right .photo-and-video__element a {
      position: relative;
      display: block;
      overflow: hidden; }
      .photo-and-video .photo-and-video__right .photo-and-video__element a::before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.65);
        position: absolute;
        left: 0;
        top: 0;
        opacity: 1;
        transition-property: opacity;
        transition-duration: 500ms; }
      .photo-and-video .photo-and-video__right .photo-and-video__element a:hover img {
        transform: scale(1.1); }
      .photo-and-video .photo-and-video__right .photo-and-video__element a:hover span {
        color: #94cde9; }
      .photo-and-video .photo-and-video__right .photo-and-video__element a:hover::before {
        opacity: 0; }
      .photo-and-video .photo-and-video__right .photo-and-video__element a span {
        position: absolute;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        text-align: center;
        text-decoration: none;
        font-weight: 600;
        font-size: 2.25rem; }
      .photo-and-video .photo-and-video__right .photo-and-video__element a img {
        display: block;
        max-width: 100%;
        transition-property: transform;
        transition-duration: 500ms; }

@media (max-width: 860px) {
  .photo-and-video .photo-and-video__left .photo-and-video__element .photo-and-video__text p span {
    font-size: 1.125rem;
    line-height: 1.4rem; }
  .photo-and-video .photo-and-video__right .photo-and-video__element a span {
    font-size: 1.5rem; } }

@media (max-width: 640px) {
  .photo-and-video .photo-and-video__left {
    display: none; }
  .photo-and-video .photo-and-video__element {
    margin: 10px 0; }
  .photo-and-video .photo-and-video__right {
    flex-basis: 100%; }
    .photo-and-video .photo-and-video__right .photo-and-video__element img {
      width: 100%;
      margin: 0; } }

.live {
  margin: 45px 0; }
  .live .live__title {
    font-size: 1.75rem;
    line-height: 1.875rem;
    font-weight: 600;
    margin: 15px 0 25px; }
  .live .live__block {
    display: flex;
    justify-content: space-between; }

@media (max-width: 860px) {
  .live .live__block {
    flex-direction: column; } }

.live-federac {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between; }
  .live-federac .live-federac__element {
    display: flex;
    margin-bottom: 30px; }
  .live-federac .live-federac__img {
    margin-right: 30px;
    flex-basis: 47.2%; }
    .live-federac .live-federac__img a {
      text-decoration: none;
      display: block; }
    .live-federac .live-federac__img img {
      display: block;
      max-width: 100%; }
  .live-federac .live-federac__text {
    flex-basis: calc(100% - 47.2% - 30px); }
    .live-federac .live-federac__text p {
      font-size: 1.125rem;
      line-height: 1.4375rem; }
      .live-federac .live-federac__text p a {
        font-weight: 600;
        display: inline-block;
        color: #000;
        text-decoration: none;
        margin-bottom: 3px; }
        .live-federac .live-federac__text p a:hover {
          color: #94cde9; }
  .live-federac .live-federac__btn a {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block; }
    .live-federac .live-federac__btn a .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .live-federac .live-federac__btn a:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .live-federac .live-federac__btn a:hover .quote {
        transform: translateX(5px); }

@media (max-width: 880px) {
  .live-federac .live-federac__img {
    flex-basis: 30%; }
  .live-federac .live-federac__text {
    flex-basis: calc(100% - 30% - 30px); } }

@media (max-width: 580px) {
  .live-federac .live-federac__element {
    flex-direction: column; }
  .live-federac .live-federac__img {
    margin-right: 0;
    flex-basis: auto; }
    .live-federac .live-federac__img img {
      margin-bottom: 10px;
      width: 100%; } }

.programm a {
  position: relative;
  overflow: hidden;
  display: flex;
  margin-bottom: 30px; }
  .programm a:hover img {
    transform: scale(1.1);
    filter: brightness(115%); }
  .programm a:hover .programm__text p {
    color: #94cde9; }

.programm .programm__text {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
  padding: 30px 55px;
  box-sizing: border-box; }
  .programm .programm__text p {
    color: #fff;
    font-size: 2.25rem;
    line-height: 2.5rem;
    text-decoration: none;
    font-weight: 600; }
    .programm .programm__text p:hover {
      color: #94cde9; }
  .programm .programm__text .quote {
    font-size: 2rem;
    line-height: 2.5rem; }

.programm img {
  display: block;
  max-width: 100%;
  transition-duration: 500ms;
  transition-property: transform, filter;
  object-fit: cover; }

@media (max-width: 860px) {
  .programm {
    margin-bottom: 20px; }
    .programm a {
      margin-bottom: 0; }
    .programm img {
      width: 100%; } }

@media (max-width: 480px) {
  .programm .programm__text {
    padding: 25px; }
    .programm .programm__text p {
      font-size: 1.7rem;
      line-height: 2rem; } }

.partner {
  margin: 45px 0; }
  .partner .partner__title {
    font-size: 1.75rem;
    line-height: 1.875rem;
    font-weight: 600;
    margin: 15px 0 25px; }
  .partner .partner__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .partner .partner__left {
    flex-basis: 48.6%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .partner .partner__left > div {
      flex-basis: 47.2%;
      background-color: #fff;
      border-top: 5px solid #dab509;
      text-align: center;
      padding: 0 10px 25px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .partner .partner__left > div:last-child {
        border-color: #428bca; }
      .partner .partner__left > div div {
        margin-bottom: auto; }
      .partner .partner__left > div img {
        margin: 15px auto;
        display: block;
        max-width: 100%; }
      .partner .partner__left > div p {
        font-weight: 600;
        font-size: 1.1875rem;
        line-height: 1.475rem;
        margin: 5px 0;
        width: 100%;
        margin-bottom: auto; }
      .partner .partner__left > div .partner__last {
        font-size: 1.125rem;
        line-height: 1.125rem;
        font-weight: 400; }
  .partner .partner__right {
    flex-basis: 48.6%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center; }
    .partner .partner__right div {
      flex-basis: 33%;
      margin-bottom: 20px; }
      .partner .partner__right div img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        padding: 0 5%;
        box-sizing: border-box; }

@media (max-width: 880px) {
  .partner .partner__left {
    flex-basis: 100%; }
  .partner .partner__right {
    flex-basis: 100%;
    margin-top: 20px; } }

@media (max-width: 480px) {
  .partner .partner__left > div {
    flex-basis: 100%;
    margin: 10px 0;
    height: auto; } }

.footer {
  background-color: #428bca;
  position: relative;
  border-top: 1px solid #000;
  padding-bottom: 15px; }
  .footer::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0)); }
  .footer .footer__menu {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1; }
    .footer .footer__menu ul {
      margin-right: 50px;
      padding: 15px 0;
      position: relative; }
      .footer .footer__menu ul:hover::before {
        content: "";
        height: 3px;
        width: 100%;
        background-color: #000;
        display: block;
        margin: 0 auto;
        position: absolute;
        left: 0;
        top: 0; }
      .footer .footer__menu ul:last-child {
        margin-right: 0; }
      .footer .footer__menu ul li:first-child {
        margin-bottom: 7px; }
        .footer .footer__menu ul li:first-child a {
          font-size: 1.5rem;
          line-height: 2.1875rem; }
      .footer .footer__menu ul li a {
        color: #fff;
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 600;
        text-decoration: none; }
        .footer .footer__menu ul li a:hover {
          color: #94cde9; }
  .footer .footer__bottom {
    position: relative;
    z-index: 1;
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
    flex-wrap: wrap; }
  .footer .footer__left {
    flex-basis: 480px;
    box-sizing: border-box;
    display: flex; }
    .footer .footer__left .footer__logo {
      flex-basis: 57px;
      flex-shrink: 0;
      margin-right: 12px;
      margin-top: 12px; }
      .footer .footer__left .footer__logo img {
        max-width: 100%;
        display: block; }
    .footer .footer__left p {
      margin: 15px 0;
      line-height: 1.4375rem;
      color: #fff; }
  .footer .footer__right .footer__phone {
    text-align: right;
    margin: 15px 0; }
    .footer .footer__right .footer__phone a {
      background: url("../img/mobile-phone.png") left center no-repeat;
      padding-left: 30px;
      font-weight: 800;
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: #fff;
      text-decoration: none; }
  .footer .footer__right .footer__socials {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 30px 0; }
    .footer .footer__right .footer__socials p {
      font-size: 1rem;
      line-height: 1.4375rem;
      color: #fff; }
    .footer .footer__right .footer__socials a {
      margin: 0 5px; }
      .footer .footer__right .footer__socials a:first-of-type {
        margin-left: 15px; }
      .footer .footer__right .footer__socials a img {
        display: block;
        width: 32px; }

@media (max-width: 880px) {
  .footer .footer__menu ul {
    margin-right: 25px; }
    .footer .footer__menu ul li:first-child a {
      font-size: 1.3rem; }
    .footer .footer__menu ul li a {
      font-size: 1.125rem; } }

@media (max-width: 768px) {
  .footer .footer__menu {
    display: none; }
  .footer .footer__bottom {
    flex-direction: column;
    margin: 10px 0; }
    .footer .footer__bottom .footer__left {
      flex-basis: auto;
      align-self: center;
      order: 2; }
    .footer .footer__bottom .footer__right {
      flex-basis: auto;
      align-self: center;
      order: 1; }
    .footer .footer__bottom .footer__phone {
      text-align: center; }
    .footer .footer__bottom .footer__socials {
      margin: 15px 0; } }

.content {
  background-color: #e3e3e3;
  padding: 15px 0; }
  .content .content__left {
    flex-basis: 255px; }
  .content .content__right {
    flex-basis: calc(100% - 30px - 255px);
    box-sizing: border-box; }
  .content .content__right--full {
    flex-basis: 100%; }
  .content .content__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .content h1 {
    font-size: 2.25rem;
    line-height: 2.8125rem;
    font-weight: 700;
    margin: 15px 0 20px; }

@media (max-width: 860px) {
  .content .content__wrap {
    flex-direction: column; }
  .content .content__left {
    flex-basis: auto;
    margin-bottom: 20px; }
  .content .content__right {
    flex-basis: auto; } }

@media (max-width: 768px) {
  .content h1 {
    font-size: 1.75rem;
    line-height: 2.25rem; } }

.links {
  background-color: #fff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  padding: 25px 0 25px 20px;
  position: sticky;
  top: 0; }
  .links li {
    padding: 5px 10px 5px 12px; }
  .links a {
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: #428bca;
    text-decoration: none; }
    .links a:hover {
      color: #94cde9; }
  .links .links__active {
    background-color: #f6f5f5;
    border-right: 3px solid #000; }

@media (max-width: 768px) {
  .links {
    padding-left: 10px; } }

.documents {
  background-color: #fff;
  padding: 5px 30px 20px 30px; }
  .documents .documents__title {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;
    margin: 15px 0; }
  .documents .documents__block {
    margin: 20px 0 25px; }
  .documents .documents__element {
    margin: 10px 0; }
    .documents .documents__element p {
      background: url("../img/pdf.png") left center no-repeat;
      padding-left: 40px;
      line-height: 1.625rem; }
    .documents .documents__element a {
      text-decoration: none;
      border-bottom: 1px solid #428bca;
      line-height: 1.625rem;
      color: #000; }
      .documents .documents__element a:hover {
        border-bottom-color: transparent;
        color: #94cde9; }
    .documents .documents__element span {
      color: #8b8a8a; }

@media (max-width: 768px) {
  .documents {
    padding-left: 20px;
    padding-right: 20px; }
    .documents .documents__title {
      font-size: 1.25rem;
      line-height: 1.75rem; } }

.breadcrumbs {
  margin: 15px 0; }
  .breadcrumbs .breadcrumbs__arrow {
    margin: 0 5px; }
  .breadcrumbs a {
    font-size: 1rem;
    line-height: 1.875rem;
    display: inline-block;
    color: #000;
    text-decoration: none; }
    .breadcrumbs a:hover {
      color: #94cde9; }
  .breadcrumbs span {
    color: #8b8a8a; }

.date-share {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -10px 0 15px; }
  .date-share .date-share__date {
    color: #8b8a8a;
    font-size: 1rem;
    line-height: 1.375rem;
    margin: 5px 45px 5px 0; }

@media (max-width: 480px) {
  .date-share {
    flex-direction: column;
    align-items: flex-start; }
    .date-share .date-share__date {
      margin-right: 0; } }

.tags {
  margin: 15px 0; }
  .tags a {
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #61bf1a;
    font-weight: 600; }
    .tags a:hover {
      color: #94cde9; }
      .tags a:hover span {
        border-bottom-color: transparent; }
  .tags span {
    border-bottom: 1px solid #61bf1a; }

.video-full {
  margin: 35px 0; }
  .video-full .video-full__title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin: 15px 0; }
  .video-full .video-full__player {
    position: relative;
    padding-bottom: 56.25%;
    /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin: 15px 0; }
    .video-full .video-full__player iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .video-full p {
    font-size: 1rem;
    line-height: 1.625rem;
    margin: 15px 0; }

.share {
  display: flex;
  align-items: center; }
  .share p {
    color: #8b8a8a;
    font-size: 1rem;
    line-height: 1.375rem;
    margin-right: 5px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .share div ul {
    margin: 0 !important; }
    .share div ul li {
      padding-left: 0 !important; }
      .share div ul li::before {
        display: none !important; }
  .share div a {
    border-bottom: none !important; }
    .share div a span {
      border-bottom: none !important; }

.back {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  flex-wrap: wrap;
  align-items: center;
  width: 100%; }
  .back a {
    font-size: 1.125rem;
    line-height: 1.625rem;
    text-decoration: none;
    color: #000;
    margin: 10px 0;
    border-bottom: none !important; }
    .back a span {
      border-bottom: 1px solid #428bca; }
    .back a .back__arrow {
      font-size: 1rem;
      color: #428bca;
      margin-right: 5px; }
    .back a:hover {
      color: #428bca; }
      .back a:hover span {
        border-bottom-color: transparent; }

.press {
  background: #fff;
  padding: 5px 30px 20px 30px; }

@media (max-width: 768px) {
  .press {
    padding-left: 20px;
    padding-right: 20px; } }

.last-video {
  margin: 45px 0; }
  .last-video .last-video__top {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .last-video .last-video__tags {
    margin: 0; }
  .last-video .last-video__title {
    font-size: 1.625rem;
    line-height: 2.1875rem;
    font-weight: 600;
    margin-right: 20px; }
  .last-video .last-video__block {
    display: flex;
    justify-content: space-between; }
  .last-video .last-video__element {
    flex-basis: 31%;
    margin: 15px 0; }
    .last-video .last-video__element a {
      display: block;
      text-decoration: none;
      color: #000;
      overflow: hidden; }
      .last-video .last-video__element a:hover img {
        filter: brightness(115%);
        transform: scale(1.1); }
      .last-video .last-video__element a:hover p span {
        color: #94cde9; }
    .last-video .last-video__element img {
      display: block;
      max-width: 100%;
      transition-duration: 500ms;
      transition-property: transform, filter; }
    .last-video .last-video__element p {
      font-size: 1.125rem;
      line-height: 1.375rem;
      color: #8b8a8a;
      margin: 10px 0; }
    .last-video .last-video__element span {
      color: #000; }
    .last-video .last-video__element .last-video__img {
      overflow: hidden; }
  .last-video .last-video__btn {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block;
    max-width: 350px;
    padding: 11px 0; }
    .last-video .last-video__btn .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .last-video .last-video__btn:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .last-video .last-video__btn:hover .quote {
        transform: translateX(5px); }

@media (max-width: 640px) {
  .last-video .last-video__block {
    flex-direction: column; }
  .last-video .last-video__element img {
    width: 100%; } }

.last-news {
  margin: 45px 0; }
  .last-news .last-news__top {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .last-news .last-news__tags {
    margin: 0; }
  .last-news .last-news__title {
    font-size: 1.625rem;
    line-height: 2.1875rem;
    font-weight: 600;
    margin-right: 20px; }
  .last-news .last-news__element {
    margin: 10px 0; }
    .last-news .last-news__element a {
      font-size: 1.125rem;
      line-height: 1.75rem;
      text-decoration: none;
      color: #8b8a8a; }
      .last-news .last-news__element a:hover span {
        color: #94cde9; }
    .last-news .last-news__element span {
      color: #000; }
  .last-news .last-news__btn {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block;
    max-width: 350px;
    margin-top: 25px;
    padding: 11px 0; }
    .last-news .last-news__btn .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .last-news .last-news__btn:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .last-news .last-news__btn:hover .quote {
        transform: translateX(5px); }

.last-photo {
  margin: 45px 0; }
  .last-photo .last-photo__top {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .last-photo .last-photo__tags {
    margin: 0; }
  .last-photo .last-photo__title {
    font-size: 1.625rem;
    line-height: 2.1875rem;
    font-weight: 600;
    margin-right: 20px; }
  .last-photo .last-photo__block {
    display: flex;
    justify-content: space-between; }
  .last-photo .last-photo__element {
    flex-basis: 31%;
    margin: 15px 0; }
    .last-photo .last-photo__element a {
      display: block;
      text-decoration: none;
      color: #000;
      overflow: hidden; }
      .last-photo .last-photo__element a:hover img {
        filter: brightness(115%);
        transform: scale(1.1); }
      .last-photo .last-photo__element a:hover p span {
        color: #94cde9; }
    .last-photo .last-photo__element img {
      display: block;
      max-width: 100%;
      transition-duration: 500ms;
      transition-property: transform, filter; }
    .last-photo .last-photo__element p {
      font-size: 1.125rem;
      line-height: 1.375rem;
      color: #8b8a8a;
      margin: 10px 0; }
    .last-photo .last-photo__element span {
      color: #000; }
    .last-photo .last-photo__element .last-photo__img {
      overflow: hidden; }
  .last-photo .last-photo__btn {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block;
    max-width: 350px;
    padding: 11px 0; }
    .last-photo .last-photo__btn .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .last-photo .last-photo__btn:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .last-photo .last-photo__btn:hover .quote {
        transform: translateX(5px); }

@media (max-width: 640px) {
  .last-photo .last-photo__block {
    flex-direction: column; }
  .last-photo .last-photo__element img {
    width: 100%; } }

.news {
  background: #fff;
  padding: 5px 30px 20px 30px; }
  .news .news__block h2 {
    line-height: 2.1875rem;
    font-size: 1.625rem;
    margin: 25px 0 10px 0;
    font-weight: 600; }
    .news .news__block h2 + p {
      margin-top: 10px; }
  .news .news__block h3 {
    line-height: 2.1875rem;
    font-size: 1.5rem;
    margin: 25px 0 10px 0;
    font-weight: 600; }
    .news .news__block h3 + p {
      margin-top: 10px; }
  .news .news__block p {
    font-size: 1rem;
    line-height: 1.625rem;
    margin: 25px 0; }
  .news .news__block a {
    border-bottom: 1px solid #428bca;
    text-decoration: none;
    color: #000; }
    .news .news__block a:hover {
      border-bottom-color: transparent;
      color: #94cde9; }
  .news .news__block ul {
    margin: 25px 0; }
    .news .news__block ul li {
      padding-left: 20px;
      position: relative;
      font-size: 1rem;
      line-height: 1.625rem; }
      .news .news__block ul li::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #428bca;
        border-radius: 50%;
        display: block;
        left: 5px;
        top: 50%;
        transform: translateY(-50%); }
  .news .news__block ol {
    counter-reset: olItemCounter;
    margin: 25px 0; }
    .news .news__block ol li {
      font-size: 1rem;
      line-height: 1.625rem; }
      .news .news__block ol li:before {
        counter-increment: olItemCounter;
        content: counter(olItemCounter) ".";
        font-size: 1rem;
        line-height: 1.625rem;
        text-align: center;
        box-sizing: border-box;
        color: #428bca;
        margin-right: 7px;
        display: inline-block; }

@media (max-width: 768px) {
  .news {
    padding-left: 20px;
    padding-right: 20px; } }

.img {
  float: left;
  margin: 10px 30px 0 0;
  max-width: 44%; }
  .img img {
    display: block;
    max-width: 100%; }

@media (max-width: 480px) {
  .img {
    float: none;
    max-width: 100%;
    margin-right: 0; }
    .img img {
      margin: 0 auto; } }

.full-img {
  margin: 30px 0; }
  .full-img img {
    max-width: 100%;
    display: block; }
  .full-img p {
    color: #8b8a8a;
    margin: 10px 0 0 !important; }

.file {
  margin: 25px 0; }
  .file a {
    background: url("../img/pdf.png") 10px center no-repeat;
    border-bottom: none !important;
    background-color: #f3f3f3;
    padding: 7px 25px 7px 50px;
    line-height: 1.625rem;
    display: inline-block; }
    .file a:hover span {
      border-bottom-color: transparent; }
    .file a span {
      border-bottom: 1px solid #428bca; }

.table {
  margin: 25px 0;
  overflow: auto; }
  .table .table__title {
    font-weight: 600;
    font-size: 1.25rem !important;
    line-height: 2.0625rem !important;
    margin: 0 !important;
    text-align: center; }
  .table table {
    width: 100%;
    border: 1px solid #e1f3fd;
    min-width: 500px; }
    .table table tr:nth-child(odd) {
      background-color: #f0f7fb; }
    .table table tr:first-child {
      background-color: #5abddd; }
      .table table tr:first-child td {
        border: 1px solid #5abddd;
        color: #fff; }
    .table table tr td {
      font-size: 1.125rem;
      line-height: 2.0625rem;
      color: #000;
      padding: 8px 0 8px 15px;
      border: 1px solid #e1f3fd;
      box-sizing: border-box; }

.gallery {
  display: flex;
  flex-wrap: wrap; }
  .gallery .gallery__element {
    flex-basis: 31%;
    margin: 5px 3.5% 5px 0; }
    .gallery .gallery__element:nth-child(3n + 3) {
      margin-right: 0; }
    .gallery .gallery__element a {
      border-bottom: none; }
    .gallery .gallery__element img {
      display: block;
      max-width: 100%; }

@media (max-width: 580px) {
  .gallery .gallery__element {
    flex-basis: 48%; }
    .gallery .gallery__element:nth-child(3n + 3) {
      margin-right: 3.5%; }
    .gallery .gallery__element:nth-child(2n + 2) {
      margin-right: 0; } }

.service {
  background-color: #fff;
  padding: 5px 30px 20px 30px; }
  .service .service__phone {
    margin: 12px 0;
    padding-left: 50px;
    box-sizing: border-box;
    background: url("../img/mobile-phone.svg") left center no-repeat;
    background-size: 20px; }
    .service .service__phone a {
      font-size: 1.5rem;
      line-height: 2.9375rem;
      color: #000;
      font-weight: 600;
      text-decoration: none; }
      .service .service__phone a:hover {
        color: #94cde9; }
    .service .service__phone span {
      color: #8b8a8a;
      font-size: 1.125rem; }
  .service .service__mail {
    margin: 12px 0; }
    .service .service__mail a {
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: #000;
      text-decoration: none;
      background: url("../img/envelope.svg") left center no-repeat;
      background-size: 25px;
      padding-left: 50px; }
      .service .service__mail a span {
        border-bottom: 1px solid #428bca; }
      .service .service__mail a:hover {
        color: #94cde9; }
        .service .service__mail a:hover span {
          border-bottom-color: transparent; }
  .service .service__address {
    margin: 20px 0; }
    .service .service__address p {
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: #000;
      text-decoration: none;
      background: url("../img/placeholder.svg") left center no-repeat;
      background-size: 20px;
      padding-left: 50px; }
    .service .service__address span {
      color: #8b8a8a;
      font-size: 1.125rem; }
  .service .service__block {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0; }
  .service .service__element {
    flex-basis: 31%;
    margin: 10px 3.5% 10px 0; }
    .service .service__element:nth-child(3n + 3) {
      margin-right: 0; }
    .service .service__element img {
      display: block;
      max-width: 100%;
      transition-property: filter, transform;
      transition-duration: 500ms; }
    .service .service__element a {
      color: #000;
      text-decoration: none;
      display: block; }
      .service .service__element a .service__img {
        overflow: hidden; }
      .service .service__element a .service__name {
        font-size: 1.375rem;
        line-height: 1.75rem;
        font-weight: 600;
        display: block;
        margin-top: 10px; }
      .service .service__element a:hover {
        color: #94cde9; }
        .service .service__element a:hover img {
          filter: brightness(115%);
          transform: scale(1.1); }
    .service .service__element p {
      font-size: 1.125rem;
      line-height: 1.75rem; }
      .service .service__element p a {
        border-bottom: 1px solid #428bca;
        display: inline-block; }
        .service .service__element p a:hover {
          border-bottom-color: transparent; }

@media (max-width: 768px) {
  .service {
    padding-left: 20px;
    padding-right: 20px; } }

@media (max-width: 560px) {
  .service .service__block {
    flex-direction: column; }
  .service .service__element {
    flex-basis: auto;
    margin-bottom: 20px;
    margin-right: 0; }
    .service .service__element img {
      width: 100%; } }

.centre .breadcrumbs {
  margin-top: -5px; }

.centre .centre__block {
  display: flex;
  flex-wrap: wrap; }

.centre .centre__element {
  flex-basis: 31%;
  margin: 0 3.5% 30px 0; }
  .centre .centre__element:nth-child(3n + 3) {
    margin-right: 0; }
  .centre .centre__element .centre__img {
    position: relative;
    overflow: hidden; }
    .centre .centre__element .centre__img a {
      display: block; }
      .centre .centre__element .centre__img a:hover img {
        filter: brightness(115%);
        transform: scale(1.1); }
    .centre .centre__element .centre__img img {
      width: 100%;
      display: block;
      transition-property: filter, transform;
      transition-duration: 500ms; }
    .centre .centre__element .centre__img p {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1rem;
      line-height: 1.375rem;
      color: #fff;
      background-color: #000;
      padding: 2px 15px; }
  .centre .centre__element .centre__link {
    font-size: 1rem;
    line-height: 1.375rem;
    color: #8b8a8a;
    margin: 10px 0; }
    .centre .centre__element .centre__link a {
      text-decoration: none;
      font-weight: 600;
      color: #000; }
      .centre .centre__element .centre__link a:hover {
        color: #94cde9; }
  .centre .centre__element .centre__tags {
    margin: 10px 0; }
    .centre .centre__element .centre__tags a {
      margin-right: 5px; }
      .centre .centre__element .centre__tags a:last-child {
        margin-right: 0; }

.centre .centre__btn {
  margin: 0 0 20px; }
  .centre .centre__btn a {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
    padding: 11px 0; }
    .centre .centre__btn a .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .centre .centre__btn a:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .centre .centre__btn a:hover .quote {
        transform: translateX(5px); }
    .centre .centre__btn a .centre__arr {
      transform: rotate(90deg);
      margin-left: 5px; }

@media (max-width: 640px) {
  .centre .centre__element {
    flex-basis: 48%; }
    .centre .centre__element:nth-child(3n + 3) {
      margin-right: 3.5%; }
    .centre .centre__element:nth-child(2n + 2) {
      margin-right: 0; } }

@media (max-width: 480px) {
  .centre .centre__block {
    flex-direction: column; }
  .centre .centre__element {
    flex-basis: auto;
    margin-bottom: 20px;
    margin-right: 0; }
    .centre .centre__element:nth-child(3n + 3) {
      margin-right: 0; }
    .centre .centre__element .centre__img img {
      width: 100%; } }

.all-tags {
  margin: 15px 0; }
  .all-tags .all-tags__show a, .all-tags .all-tags__hide a {
    font-size: 1rem;
    line-height: 1.375rem;
    display: inline-block;
    color: #61bf1a;
    border: 1px solid #61bf1a;
    text-decoration: none;
    margin-right: 12px;
    padding: 1px 12px;
    box-sizing: border-box;
    margin-bottom: 12px; }
    .all-tags .all-tags__show a:hover, .all-tags .all-tags__hide a:hover {
      border-color: #94cde9;
      color: #94cde9; }
    .all-tags .all-tags__show a:last-child, .all-tags .all-tags__hide a:last-child {
      margin-right: 0; }
  .all-tags .all-tags__show .all-tags__link--active, .all-tags .all-tags__hide .all-tags__link--active {
    background-color: #61bf1a;
    color: #fff; }
    .all-tags .all-tags__show .all-tags__link--active:hover, .all-tags .all-tags__hide .all-tags__link--active:hover {
      border-color: #61bf1a;
      color: #fff; }
  .all-tags .all-tags__hide {
    display: none; }
  .all-tags .all-tags__btn {
    color: #61bf1a;
    font-size: 1rem;
    line-height: 1.375rem;
    text-decoration: none; }
    .all-tags .all-tags__btn:hover {
      color: #94cde9; }
      .all-tags .all-tags__btn:hover .all-tags__down {
        color: #94cde9; }
      .all-tags .all-tags__btn:hover span {
        border-bottom-color: transparent; }
    .all-tags .all-tags__btn span {
      border-bottom: 1px dashed #61bf1a; }
    .all-tags .all-tags__btn .all-tags__down {
      font-size: 1rem;
      line-height: 1.375rem; }

.all-tags--active .all-tags__hide {
  display: inline; }

.all-tags--active .all-tags__down {
  transform: rotate(180deg); }

.sort {
  margin: 20px 0; }
  .sort .sort__block {
    display: flex; }
  .sort .sort__element {
    flex-basis: 180px;
    margin-right: 20px; }
    .sort .sort__element:last-of-type {
      margin-right: 0; }
    .sort .sort__element .jq-selectbox__select {
      width: 100%; }
  .sort .sort__element--big {
    flex-basis: 220px; }
  .sort .sort__maps {
    flex-basis: 250px;
    margin-left: 20px;
    display: flex;
    align-items: center; }
    .sort .sort__maps a {
      width: 125px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      color: #fff;
      font-size: 1.125rem;
      line-height: 1.5rem;
      box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.28);
      background-color: #61bf1a;
      background-image: linear-gradient(to top, rgba(92, 184, 92, 0.52), rgba(68, 134, 68, 0.52));
      padding: 4.5px 0; }
      .sort .sort__maps a:hover {
        background-color: #4a9214; }
    .sort .sort__maps .sort__active {
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
      background-color: #428bca;
      padding: 6px 0;
      background-image: none; }
      .sort .sort__maps .sort__active:hover {
        background-color: #428bca; }

@media (max-width: 680px) {
  .sort--big .sort__block {
    flex-direction: column; }
    .sort--big .sort__block .sort__element {
      flex-basis: auto;
      margin: 10px 0; } }

@media (max-width: 480px) {
  .sort .sort__block {
    flex-direction: column; }
  .sort .sort__element {
    flex-basis: auto;
    margin: 10px 0; } }

.pagination {
  margin: 20px 0; }
  .pagination ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .pagination li {
    flex-basis: 30px;
    flex-shrink: 0; }
  .pagination a {
    font-size: 1.125rem;
    line-height: 1.375rem;
    text-decoration: none;
    display: block;
    font-weight: 600;
    color: #000;
    width: 100%;
    text-align: center;
    border-radius: 50%;
    padding: 4px 0; }
    .pagination a:hover {
      background-color: #acacac;
      color: #fff; }
  .pagination .pagination__element--active a {
    background-color: #acacac;
    color: #fff; }

.photo {
  background: #fff;
  padding: 5px 30px 20px 30px; }
  .photo .photo__block {
    display: flex;
    flex-wrap: wrap; }
  .photo .photo__element {
    flex-basis: 31%;
    margin: 7px 3.5% 7px 0; }
    .photo .photo__element:nth-child(3n + 3) {
      margin-right: 0; }
    .photo .photo__element a {
      display: block;
      overflow: hidden; }
      .photo .photo__element a:hover img {
        filter: brightness(115%);
        transform: scale(1.1); }
    .photo .photo__element img {
      display: block;
      max-width: 100%;
      transition-property: filter, transform;
      transition-duration: 500ms; }

@media (max-width: 768px) {
  .photo {
    padding-left: 20px;
    padding-right: 20px; } }

@media (max-width: 640px) {
  .photo .photo__element {
    flex-basis: 48%;
    margin-right: 3.5%; }
    .photo .photo__element:nth-child(3n + 3) {
      margin-right: 3.5%; }
    .photo .photo__element:nth-child(2n + 2) {
      margin-right: 0; } }

@media (max-width: 480px) {
  .photo .photo__block {
    flex-direction: column;
    flex-wrap: nowrap; }
  .photo .photo__element {
    flex-basis: auto;
    margin-bottom: 10px;
    margin-right: 0; }
    .photo .photo__element:nth-child(3n + 3) {
      margin-right: 0; }
    .photo .photo__element img {
      width: 100%; } }

.sportsmen .breadcrumbs {
  margin-top: -5px; }

.sportsmen .sportsmen__block {
  display: flex;
  margin: 30px 0;
  flex-wrap: wrap; }

.sportsmen .sportsmen__element {
  flex-basis: 31%;
  margin: 10px 3.5% 25px 0; }
  .sportsmen .sportsmen__element:nth-child(3n + 3) {
    margin-right: 0; }
  .sportsmen .sportsmen__element img {
    display: block;
    width: 100%;
    transition-property: filter, transform;
    transition-duration: 500ms; }
  .sportsmen .sportsmen__element a {
    color: #000;
    text-decoration: none;
    display: block; }
    .sportsmen .sportsmen__element a .sportsmen__img {
      overflow: hidden; }
    .sportsmen .sportsmen__element a .sportsmen__name {
      font-size: 1.375rem;
      line-height: 1.75rem;
      font-weight: 600;
      display: block;
      margin-top: 10px; }
    .sportsmen .sportsmen__element a:hover {
      color: #94cde9; }
      .sportsmen .sportsmen__element a:hover img {
        filter: brightness(115%);
        transform: scale(1.1); }
  .sportsmen .sportsmen__element p {
    font-size: 1.125rem;
    line-height: 1.75rem; }

@media (max-width: 560px) {
  .sportsmen .sportsmen__element {
    flex-basis: 100%;
    margin-right: 0; }
    .sportsmen .sportsmen__element:last-child {
      margin-bottom: 0; } }

.person {
  background: #fff;
  padding: 5px 30px 20px 30px; }
  .person .person__block::after {
    content: '';
    display: block;
    clear: both; }
  .person .person__block h2 {
    line-height: 2.1875rem;
    font-size: 1.625rem;
    margin: 25px 0 10px 0;
    font-weight: 600; }
    .person .person__block h2 + p {
      margin-top: 10px; }
  .person .person__block h3 {
    line-height: 2.1875rem;
    font-size: 1.5rem;
    margin: 25px 0 10px 0;
    font-weight: 600; }
    .person .person__block h3 + p {
      margin-top: 10px; }
  .person .person__block p {
    font-size: 1rem;
    line-height: 1.625rem;
    margin: 25px 0; }
  .person .person__block a {
    border-bottom: 1px solid #428bca;
    text-decoration: none;
    color: #000; }
    .person .person__block a:hover {
      border-bottom-color: transparent;
      color: #94cde9; }
  .person .person__block ul {
    margin: 25px 0; }
    .person .person__block ul li {
      padding-left: 20px;
      position: relative;
      font-size: 1rem;
      line-height: 1.625rem; }
      .person .person__block ul li::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: #428bca;
        border-radius: 50%;
        display: block;
        left: 5px;
        top: 50%;
        transform: translateY(-50%); }
  .person .person__block ol {
    counter-reset: olItemCounter;
    margin: 25px 0; }
    .person .person__block ol li {
      font-size: 1rem;
      line-height: 1.625rem; }
      .person .person__block ol li:before {
        counter-increment: olItemCounter;
        content: counter(olItemCounter) ".";
        font-size: 1rem;
        line-height: 1.625rem;
        text-align: center;
        box-sizing: border-box;
        color: #428bca;
        margin-right: 7px;
        display: inline-block; }

@media (max-width: 768px) {
  .person {
    padding-left: 20px;
    padding-right: 20px; } }

.results .breadcrumbs {
  margin-top: -5px; }

.results .results__block {
  margin: 25px 0; }

.results .results__element {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 25px 15px 30px;
  background-color: #fff; }
  .results .results__element:nth-child(odd) {
    background-color: #f5f5f5; }
  .results .results__element .results__date {
    flex-basis: 185px;
    flex-shrink: 0; }
    .results .results__element .results__date p {
      font-size: 1.125rem;
      line-height: 1.5625rem;
      color: #428bca; }
  .results .results__element .results__link {
    flex-basis: calc(100% - 185px);
    position: relative; }
    .results .results__element .results__link a {
      font-size: 1.25rem;
      line-height: 1.875rem;
      text-decoration: none;
      color: #000;
      padding-right: 60px;
      display: inline-block; }
      .results .results__element .results__link a:hover {
        color: #94cde9; }
      .results .results__element .results__link a span {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }

.results .results__btn {
  margin: 0 0 20px; }
  .results .results__btn a {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
    padding: 11px 0; }
    .results .results__btn a .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .results .results__btn a:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .results .results__btn a:hover .quote {
        transform: translateX(5px); }
    .results .results__btn a .results__arr {
      transform: rotate(90deg);
      margin-left: 5px; }

@media (max-width: 860px) {
  .results .results__element {
    padding: 15px; }
    .results .results__element .results__link {
      flex-basis: calc(100% - 140px); }
      .results .results__element .results__link a {
        padding-right: 35px; }
    .results .results__element .results__date {
      flex-basis: 140px; } }

@media (max-width: 480px) {
  .results .results__element {
    flex-direction: column; }
    .results .results__element .results__link {
      flex-basis: auto; }
    .results .results__element .results__date {
      flex-basis: auto; } }

.competition .breadcrumbs {
  margin-top: -5px; }

.competition .competition__info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center; }
  .competition .competition__info .competiton__left {
    flex-basis: 380px; }
  .competition .competition__info .competition__right {
    flex-basis: 42%; }
  .competition .competition__info .competition__city {
    font-size: 1rem;
    line-height: 1.375rem;
    background: url("../img/flag.png") left 5px no-repeat;
    padding: 5px 0 15px 35px;
    margin: 20px 0; }
  .competition .competition__info .competition__member {
    display: flex;
    justify-content: space-between;
    margin: 20px 0; }
    .competition .competition__info .competition__member div {
      flex-basis: 30%; }
      .competition .competition__info .competition__member div p {
        font-size: 3.75rem;
        font-weight: 700; }
      .competition .competition__info .competition__member div span {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 400;
        display: block; }
  .competition .competition__info .competition__judge {
    border: 1px solid #949494;
    padding: 5px 15px 5px 35px;
    box-sizing: border-box; }
    .competition .competition__info .competition__judge p {
      font-size: 1rem;
      line-height: 1.5rem;
      color: #8b8a8a;
      margin: 15px 0; }
    .competition .competition__info .competition__judge span {
      color: #000;
      display: block; }

.competition .competition__position {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 15px 0 10px; }
  .competition .competition__position div {
    flex-basis: 220px;
    margin: 10px 20px 10px 0; }
    .competition .competition__position div:last-child {
      margin-right: 0; }
    .competition .competition__position div a {
      color: #000;
      text-decoration: none;
      font-size: 1rem;
      line-height: 1.625rem;
      display: inline-block;
      padding-left: 40px;
      box-sizing: border-box;
      background: url("../img/pdf.png") left center no-repeat; }
      .competition .competition__position div a span {
        border-bottom: 1px solid #428bca; }
      .competition .competition__position div a:hover span {
        border-bottom-color: transparent; }

.competition .competition__result {
  margin: 50px 0 15px; }

.competition .competition__title {
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.125rem;
  margin: 15px 0 0; }

.competition .competition__top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.competition .competition__sort {
  flex-basis: 440px;
  margin-top: 15px; }

.competition .competition__block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 5px 0; }

.competition .competition__element {
  background-color: #fff;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  flex-basis: 49%;
  padding: 20px 25px 25px 30px;
  box-sizing: border-box;
  margin-bottom: 15px; }
  .competition .competition__element:nth-child(odd) {
    margin-right: 2%; }
  .competition .competition__element .competition__type {
    font-weight: 600;
    font-size: 1.5rem;
    margin: 10px 0 30px; }
  .competition .competition__element .competition__gold, .competition .competition__element .competition__silver, .competition .competition__element .competition__bronze {
    font-size: 1.125rem;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    margin: 20px 0; }
    .competition .competition__element .competition__gold a, .competition .competition__element .competition__silver a, .competition .competition__element .competition__bronze a {
      text-decoration: none;
      color: #000; }
      .competition .competition__element .competition__gold a:hover, .competition .competition__element .competition__silver a:hover, .competition .competition__element .competition__bronze a:hover {
        color: #428bca; }
    .competition .competition__element .competition__gold span, .competition .competition__element .competition__silver span, .competition .competition__element .competition__bronze span {
      flex-basis: 31px;
      flex-shrink: 0;
      border: 3px solid #dab509;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      line-height: 1.125rem;
      font-size: 1rem;
      font-weight: 600;
      box-sizing: border-box;
      padding: 3.5px 0;
      margin-right: 10px; }
  .competition .competition__element .competition__silver span {
    border-color: #a1a1a1; }
  .competition .competition__element .competition__bronze span {
    border-color: #ae7058; }

@media (max-width: 1024px) {
  .competition .competition__info .competiton__left {
    flex-basis: 100%; }
  .competition .competition__info .competition__right {
    flex-basis: 100%; }
  .competition .competition__top {
    margin-bottom: 20px; }
  .competition .competition__sort {
    flex-basis: 100%; } }

@media (max-width: 860px) {
  .competition .competition__element {
    padding: 15px; }
    .competition .competition__element .competition__gold span, .competition .competition__element .competition__silver span, .competition .competition__element .competition__bronze span {
      padding: 5px 0; } }

@media (max-width: 768px) {
  .competition .competition__position div {
    flex-basis: 100%; } }

@media (max-width: 560px) {
  .competition .competition__element {
    flex-basis: 100%; }
    .competition .competition__element:nth-child(odd) {
      margin-right: 0; } }

.toss .breadcrumbs {
  margin-top: -5px; }

.toss .toss__top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.toss .toss__sort {
  flex-basis: 440px;
  margin-top: 15px; }

.toss .toss__element {
  margin: 0 0 50px 0; }
  .toss .toss__element .toss__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-weight: 600;
    margin: 20px 0; }

.toss .toss__table {
  display: flex;
  flex: 1;
  padding-right: 70px;
  box-sizing: border-box; }
  .toss .toss__table .toss__column {
    display: flex;
    flex-direction: column;
    max-width: 145px;
    min-width: 66px;
    flex: 1; }
    .toss .toss__table .toss__column p {
      font-size: 0.875rem;
      color: #61bf1a;
      line-height: 1.125rem;
      position: absolute;
      bottom: -25px;
      max-width: 115px; }
    .toss .toss__table .toss__column:first-child .toss__man {
      height: 123px; }
    .toss .toss__table .toss__column:nth-child(2) .toss__man {
      margin: 27px 0 26px 0;
      height: 193px;
      transform: translateX(49px); }
      .toss .toss__table .toss__column:nth-child(2) .toss__man a:last-child {
        margin-top: 87px; }
        .toss .toss__table .toss__column:nth-child(2) .toss__man a:last-child::after {
          content: "";
          width: 25px;
          height: 1px;
          border-bottom: 1px solid #428bca;
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%); }
      .toss .toss__table .toss__column:nth-child(2) .toss__man a:first-child::after {
        content: "";
        width: 25px;
        height: 1px;
        border-bottom: 1px solid #428bca;
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%); }
      .toss .toss__table .toss__column:nth-child(2) .toss__man a:first-child::before {
        height: 124px; }
    .toss .toss__table .toss__column:nth-child(3) .toss__man {
      margin: 89px 0 89px 0px;
      height: 316px;
      transform: translateX(98px); }
      .toss .toss__table .toss__column:nth-child(3) .toss__man a:last-child {
        margin-top: 210px; }
        .toss .toss__table .toss__column:nth-child(3) .toss__man a:last-child::after {
          content: "";
          width: 25px;
          height: 1px;
          border-bottom: 1px solid #428bca;
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%); }
      .toss .toss__table .toss__column:nth-child(3) .toss__man a::before {
        height: 247px; }
    .toss .toss__table .toss__column:nth-child(4) .toss__man {
      margin: 212px 0 212px 0px;
      height: 565px;
      transform: translateX(147px); }
      .toss .toss__table .toss__column:nth-child(4) .toss__man::after {
        right: -24px;
        width: 70px; }
      .toss .toss__table .toss__column:nth-child(4) .toss__man a:last-child {
        margin-top: 459px; }
        .toss .toss__table .toss__column:nth-child(4) .toss__man a:last-child::after {
          content: "";
          width: 25px;
          height: 1px;
          border-bottom: 1px solid #428bca;
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%); }
      .toss .toss__table .toss__column:nth-child(4) .toss__man a::before {
        height: 496px; }
    .toss .toss__table .toss__column:nth-child(5) {
      transform: translateX(1px); }
      .toss .toss__table .toss__column:nth-child(5) .toss__man {
        margin: 478px 0 0 0;
        height: 53px; }
        .toss .toss__table .toss__column:nth-child(5) .toss__man::after {
          display: none; }
        .toss .toss__table .toss__column:nth-child(5) .toss__man a {
          margin-bottom: 0; }
          .toss .toss__table .toss__column:nth-child(5) .toss__man a:last-child, .toss .toss__table .toss__column:nth-child(5) .toss__man a:first-child {
            margin-top: 0; }
            .toss .toss__table .toss__column:nth-child(5) .toss__man a:last-child::after, .toss .toss__table .toss__column:nth-child(5) .toss__man a:first-child::after {
              display: none; }
          .toss .toss__table .toss__column:nth-child(5) .toss__man a::before {
            display: none; }
  .toss .toss__table .toss__man {
    position: relative; }
    .toss .toss__table .toss__man::after {
      content: "";
      width: 25px;
      height: 1px;
      border-bottom: 1px solid #428bca;
      position: absolute;
      right: -49px;
      top: 50%; }
    .toss .toss__table .toss__man a {
      border: 1px solid #428bca;
      font-size: 1rem;
      line-height: 1.125rem;
      display: block;
      text-align: center;
      color: #000;
      text-decoration: none;
      padding: 8px 0;
      background-color: #fff;
      margin: 17px 0;
      white-space: nowrap;
      width: 100%;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      height: 36px; }
      .toss .toss__table .toss__man a span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        white-space: nowrap; }
      .toss .toss__table .toss__man a:first-child {
        top: 0; }
        .toss .toss__table .toss__man a:first-child::after {
          content: "";
          width: 25px;
          height: 1px;
          border-bottom: 1px solid #428bca;
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%); }
        .toss .toss__table .toss__man a:first-child::before {
          content: "";
          width: 1px;
          height: 54px;
          border-left: 1px solid #428bca;
          position: absolute;
          right: -26px;
          top: 50%; }
      .toss .toss__table .toss__man a:last-child {
        bottom: 0; }
        .toss .toss__table .toss__man a:last-child::after {
          content: "";
          width: 25px;
          height: 1px;
          border-bottom: 1px solid #428bca;
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%); }
      .toss .toss__table .toss__man a:hover {
        color: #428bca; }
    .toss .toss__table .toss__man .toss__winner {
      color: #61bf1a; }

.toss .toss__table--four .toss__column:nth-child(4) .toss__man {
  height: 70px; }
  .toss .toss__table--four .toss__column:nth-child(4) .toss__man::after {
    display: none; }
  .toss .toss__table--four .toss__column:nth-child(4) .toss__man a:last-child {
    margin-top: 459px; }
    .toss .toss__table--four .toss__column:nth-child(4) .toss__man a:last-child::after {
      display: none; }
  .toss .toss__table--four .toss__column:nth-child(4) .toss__man a::before, .toss .toss__table--four .toss__column:nth-child(4) .toss__man a::after {
    display: none; }

.toss .toss__table--three .toss__column:nth-child(3) .toss__man {
  height: 70px; }
  .toss .toss__table--three .toss__column:nth-child(3) .toss__man::after {
    display: none; }
  .toss .toss__table--three .toss__column:nth-child(3) .toss__man a:last-child {
    margin-top: 459px; }
    .toss .toss__table--three .toss__column:nth-child(3) .toss__man a:last-child::after {
      display: none; }
  .toss .toss__table--three .toss__column:nth-child(3) .toss__man a::before, .toss .toss__table--three .toss__column:nth-child(3) .toss__man a::after {
    display: none; }

.toss .toss__table--two .toss__column:nth-child(2) .toss__man {
  height: 70px; }
  .toss .toss__table--two .toss__column:nth-child(2) .toss__man::after {
    display: none; }
  .toss .toss__table--two .toss__column:nth-child(2) .toss__man a:last-child {
    margin-top: 459px; }
    .toss .toss__table--two .toss__column:nth-child(2) .toss__man a:last-child::after {
      display: none; }
  .toss .toss__table--two .toss__column:nth-child(2) .toss__man a::before, .toss .toss__table--two .toss__column:nth-child(2) .toss__man a::after {
    display: none; }

@media (max-width: 1024px) {
  .toss .toss__top {
    margin-bottom: 20px; }
  .toss .toss__sort {
    flex-basis: 100%; } }

@media (max-width: 768px) {
  .toss .toss__table .toss__column:nth-child(5) {
    transform: translateX(30px); } }

@media (max-width: 640px) {
  .toss .toss__table {
    padding-right: 0; }
    .toss .toss__table .toss__column:nth-child(2) .toss__man {
      transform: translateX(5px); }
    .toss .toss__table .toss__column:nth-child(3) .toss__man {
      transform: translateX(10px); }
    .toss .toss__table .toss__column:nth-child(4) .toss__man {
      transform: translateX(15px); }
    .toss .toss__table .toss__column:nth-child(5) {
      transform: translateX(-100%); }
    .toss .toss__table .toss__man p {
      font-size: 0.7rem;
      bottom: -12px; }
    .toss .toss__table .toss__man a {
      font-size: 0.8rem; }
  .toss .toss__table--four .toss__column:nth-child(4) .toss__man {
    transform: translateX(40px); }
  .toss .toss__table--three .toss__column:nth-child(3) .toss__man {
    transform: translateX(40px); }
  .toss .toss__table--two .toss__column:nth-child(2) .toss__man {
    transform: translateX(40px); } }

.contact {
  background-color: #fff;
  padding: 5px 30px 20px 30px; }
  .contact .contact__flex {
    display: flex;
    justify-content: space-between; }
  .contact .contact__block p {
    font-size: 1rem;
    line-height: 1.625rem;
    margin: 20px 0; }
    .contact .contact__block p span {
      color: #8b8a8a;
      font-size: 1.125rem; }
  .contact .contact__block a {
    border-bottom: 1px solid #428bca;
    text-decoration: none;
    color: #000; }
    .contact .contact__block a:hover {
      border-bottom-color: transparent;
      color: #94cde9; }
  .contact .contact__img {
    flex-basis: 41.5%;
    float: none;
    margin-top: 20px; }
    .contact .contact__img img {
      display: block;
      max-width: 100%; }
  .contact .contact__phone {
    margin: 12px 0;
    padding-left: 50px;
    box-sizing: border-box;
    background: url("../img/mobile-phone.svg") left center no-repeat;
    background-size: 20px; }
    .contact .contact__phone p {
      margin: 0; }
    .contact .contact__phone a {
      font-size: 1.5rem;
      line-height: 2.9375rem;
      color: #000;
      font-weight: 600;
      text-decoration: none;
      border-bottom: none; }
      .contact .contact__phone a:hover {
        color: #94cde9; }
    .contact .contact__phone span {
      color: #8b8a8a;
      font-size: 1.125rem; }
  .contact .contact__mail {
    margin: 12px 0; }
    .contact .contact__mail a {
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: #000;
      text-decoration: none;
      background: url("../img/envelope.svg") left center no-repeat;
      background-size: 25px;
      padding-left: 50px;
      border-bottom: none; }
      .contact .contact__mail a span {
        border-bottom: 1px solid #428bca; }
      .contact .contact__mail a:hover {
        color: #94cde9; }
        .contact .contact__mail a:hover span {
          border-bottom-color: transparent; }
  .contact .contact__address {
    margin: 20px 0; }
    .contact .contact__address p {
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: #000;
      text-decoration: none;
      background: url("../img/placeholder.svg") left center no-repeat;
      background-size: 20px;
      padding-left: 50px;
      margin: 0; }
    .contact .contact__address span {
      color: #8b8a8a;
      font-size: 1.125rem; }
  .contact .contact__time {
    margin: 18px 0; }
    .contact .contact__time p {
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: #000;
      text-decoration: none;
      background: url("../img/clock.svg") left center no-repeat;
      background-size: 22px;
      margin: 0;
      padding: 2px 0 2px 50px; }
    .contact .contact__time span {
      color: #8b8a8a;
      font-size: 1.125rem; }

@media (max-width: 768px) {
  .contact {
    padding-left: 20px;
    padding-right: 20px; } }

@media (max-width: 560px) {
  .contact .contact__flex {
    flex-direction: column; }
  .contact .contact__img {
    margin-right: 0; }
    .contact .contact__img img {
      width: 100%; } }

.map {
  background-color: #fff; }
  .map .map__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-weight: 600;
    padding: 0 30px 25px; }

.clubs .breadcrumbs {
  margin-top: -5px; }

.clubs .clubs__map {
  margin-top: 35px; }

.clubs .clubs__list {
  margin-top: 35px; }

.clubs .clubs__element {
  background-color: #fff;
  padding: 15px 25px 15px 30px;
  position: relative; }
  .clubs .clubs__element:nth-of-type(even) {
    background-color: #f5f5f5; }
  .clubs .clubs__element a {
    font-size: 1.25rem;
    line-height: 1.875rem;
    text-decoration: none;
    color: #000;
    padding-right: 30px;
    display: inline-block; }
    .clubs .clubs__element a:hover {
      color: #94cde9; }
    .clubs .clubs__element a span {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%); }

.clubs .clubs__btn {
  margin: 20px 0; }
  .clubs .clubs__btn a {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 400;
    padding: 11px 0; }
    .clubs .clubs__btn a .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .clubs .clubs__btn a:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .clubs .clubs__btn a:hover .quote {
        transform: translateX(5px); }
    .clubs .clubs__btn a .clubs__arr {
      transform: rotate(90deg);
      margin-left: 5px; }

.clubs--list .clubs__map {
  display: none; }

.clubs--map .clubs__list {
  display: none; }

@media (max-width: 560px) {
  .clubs .clubs__sort .sort__block {
    flex-direction: column; }
  .clubs .clubs__sort .sort__element {
    flex-basis: auto;
    margin: 10px 0; }
  .clubs .clubs__sort .sort__maps a {
    width: 50%; } }

@media (max-width: 480px) {
  .clubs .clubs__element {
    padding: 15px 15px 15px 15px; }
    .clubs .clubs__element a span {
      right: 15px; } }

.federacia .breadcrumbs {
  margin-top: -5px; }

.federacia .federacia__title {
  font-size: 1.75rem;
  line-height: 2.2rem;
  font-weight: 600;
  color: #000;
  margin: 10px 0; }

.federacia .federacia__block {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 30px;
  box-sizing: border-box;
  margin: 0 0 50px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15); }

.federacia .federacia__elements {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap; }

.federacia .federacia__sub-title {
  flex-basis: 100%;
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 600;
  margin-top: 10px; }

.federacia .federacia__element {
  flex-basis: 31%;
  margin: 12px 3.5% 12px 0;
  align-self: flex-end; }
  .federacia .federacia__element:nth-of-type(3n + 3) {
    margin-right: 0; }
  .federacia .federacia__element img {
    display: block;
    width: 100%; }
  .federacia .federacia__element p {
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.875rem;
    margin: 10px 0; }
    .federacia .federacia__element p span {
      font-weight: 400;
      display: block; }

.federacia .federacia__block--one {
  display: flex;
  flex-direction: column;
  width: 37%; }
  .federacia .federacia__block--one .federacia__element {
    flex-basis: 100%;
    margin-right: 0; }
    .federacia .federacia__block--one .federacia__element:first-child {
      margin-right: 0; }

@media (max-width: 768px) {
  .federacia .federacia__block {
    padding-left: 20px;
    padding-right: 20px; } }

@media (max-width: 480px) {
  .federacia .federacia__element {
    flex-basis: 100%; }
    .federacia .federacia__element:nth-of-type(1n + 1) {
      margin-right: 0; }
  .federacia .federacia__block--one {
    display: flex;
    flex-direction: row;
    width: 100%; } }

.link-to {
  margin: 10px 0; }
  .link-to a {
    font-size: 1.125rem;
    line-height: 1.625rem;
    text-decoration: none;
    color: #000;
    margin: 10px 0;
    border-bottom: none !important; }
    .link-to a .link-to__quote {
      color: #428bca; }
    .link-to a span {
      border-bottom: 1px solid #428bca;
      margin-right: 3px; }
    .link-to a:hover {
      color: #428bca; }
      .link-to a:hover span {
        border-bottom-color: transparent; }

.opacity {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
  opacity: 0;
  transition-duration: 500ms;
  transition-property: opacity; }

.opacity--active {
  opacity: 1;
  z-index: 49; }

@keyframes popups {
  0% {
    transform: translate(-50%, -2000px); }
  100% {
    transform: translate(-50%, -50%); } }

.popup {
  max-width: 1110px;
  max-height: 570px;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  background-color: #fff;
  border-top: 5px solid #428bca;
  text-align: center;
  display: none;
  padding: 0;
  box-sizing: border-box; }
  .popup .popup__title {
    font-size: 1.75rem;
    line-height: 2rem;
    margin: 25px 0 35px;
    font-weight: 600;
    padding-left: 50px;
    padding-right: 50px; }
  .popup .popup__legend {
    position: absolute;
    right: 122px;
    top: 110px;
    text-align: left; }
  .popup .popup__yes {
    font-size: 1.125rem;
    line-height: 1.4rem;
    padding-left: 27px;
    position: relative;
    margin: 5px 0; }
    .popup .popup__yes::before {
      content: "";
      width: 14px;
      height: 13px;
      display: block;
      background-color: #61bf1a;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(calc(-50% + 1px)); }
  .popup .popup__no {
    font-size: 1.125rem;
    line-height: 1.4rem;
    padding-left: 27px;
    position: relative;
    margin: 5px 0; }
    .popup .popup__no::before {
      background-color: #1faae5;
      content: "";
      width: 14px;
      height: 13px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(calc(-50% + 1px)); }
  .popup .popup__close {
    appearance: none;
    border: none;
    box-shadow: none;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background-color: transparent; }
  .popup img {
    display: block;
    margin: 0 auto;
    max-width: 100%; }
  .popup .popup__ok {
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    background-color: #428bca;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5625rem;
    text-align: center;
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
    display: block;
    border: none;
    appearance: none;
    width: 190px;
    cursor: pointer;
    margin: 30px auto; }
    .popup .popup__ok .quote {
      transition-duration: 200ms;
      transition-property: transform; }
    .popup .popup__ok:hover {
      background-color: #3071a9;
      box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
      .popup .popup__ok:hover .quote {
        transform: translateX(5px); }

.popup--active {
  display: block;
  animation-name: popups;
  animation-duration: 500ms; }

@media (max-width: 768px) {
  .popup {
    max-width: 100%;
    width: calc(100% - 30px); }
    .popup .popup__legend {
      position: static;
      text-align: center;
      margin-bottom: 20px; }
    .popup .popup__title {
      margin-bottom: 20px; }
    .popup .popup__no {
      display: inline; }
    .popup .popup__yes {
      display: inline;
      margin-right: 15px; } }

@media (max-width: 640px) {
  .popup .popup__title {
    font-size: 1.5rem; }
  .popup .popup__legend {
    right: 30px;
    top: 90px; } }

.birthday .birthday__title {
  font-size: 1.75rem;
  line-height: 1.875rem;
  font-weight: 600;
  margin: 0 0 25px; }

.birthday .birthday__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.birthday .birthday__element {
  display: flex;
  flex-basis: 48%;
  margin-bottom: 32px; }

.birthday .birthday__img {
  flex-basis: 130px;
  flex-shrink: 0;
  margin-right: 20px; }
  .birthday .birthday__img img {
    display: block;
    max-width: 100%; }

.birthday .birthday__info p {
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin: 5px 0; }
  .birthday .birthday__info p span {
    color: #428bca; }

.birthday .birthday__info .birthday__name {
  font-size: 1.25rem;
  line-height: 1.5625rem;
  font-weight: 600;
  margin-top: -3px;
  text-decoration: none;
  color: #000; }
  .birthday .birthday__info .birthday__name:hover {
    color: #94cde9; }

.birthday .birthday__all {
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
  background-color: #428bca;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5625rem;
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 16px 0;
  display: block;
  max-width: 451px;
  margin-top: 5px; }
  .birthday .birthday__all .quote {
    transition-duration: 200ms;
    transition-property: transform; }
  .birthday .birthday__all:hover {
    background-color: #3071a9;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.35); }
    .birthday .birthday__all:hover .quote {
      transform: translateX(5px); }

@media (max-width: 980px) {
  .birthday .birthday__list {
    flex-direction: column; }
  .birthday .birthday__element {
    flex-basis: auto; } }

@media (max-width: 768px) {
  .birthday .birthday__list {
    flex-direction: row; }
  .birthday .birthday__element {
    flex-basis: 48%; } }

@media (max-width: 580px) {
  .birthday .birthday__list {
    flex-direction: column; }
  .birthday .birthday__element {
    flex-basis: auto; } }

.datepicker .datepicker__title {
  font-size: 1.75rem;
  line-height: 1.875rem;
  font-weight: 600;
  margin: 0 0 25px; }

.datepicker .datepicker__block {
  margin-bottom: 28px; }

.datepicker .datepicker__link {
  font-size: 1.25rem;
  line-height: 1;
  margin: 15px 0; }
  .datepicker .datepicker__link a {
    text-decoration: none;
    color: #000; }
    .datepicker .datepicker__link a:hover {
      color: #94cde9; }

@media (max-width: 580px) {
  .datepicker .datepicker__link {
    font-size: 1.1rem; } }

.master {
  font-size: 1.25rem;
  line-height: 1.875rem;
  overflow: auto;
  margin: 30px 0; }
  .master .master__table {
    width: 100%;
    text-align: left; }
    .master .master__table thead .master__men {
      background-color: #c4c4c4;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
      display: flex;
      margin-bottom: 2px; }
    .master .master__table thead th {
      font-weight: normal; }
  .master .master__number {
    flex-basis: 9.6%;
    text-align: center;
    padding: 10px 0; }
  .master .master__men {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .master .master__men:nth-child(odd) {
      background-color: #f5f5f5; }
  .master .master__name {
    padding: 10px 0;
    flex-basis: 23%; }
    .master .master__name a {
      color: #000;
      text-decoration: none; }
      .master .master__name a:hover {
        color: #94cde9; }
  .master .master__year {
    padding: 10px 0;
    flex-basis: 23%; }
  .master .master__zvanie {
    padding: 10px 0;
    flex-basis: 11.5%; }
  .master .master__team {
    padding: 10px 0;
    flex-basis: 21%; }

@media (max-width: 768px) {
  .master {
    font-size: 1rem;
    line-height: 1.4rem; } }

@media (max-width: 560px) {
  .master__wrap {
    min-width: 530px; } }

.medalist {
  font-size: 1.25rem;
  line-height: 1.875rem;
  overflow: auto;
  text-align: center;
  margin: 30px 0; }
  .medalist .medalist__element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff; }
    .medalist .medalist__element:nth-of-type(even) {
      background-color: #f5f5f5; }
    .medalist .medalist__element:first-child {
      background-color: #c4c4c4;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.35);
      display: flex;
      margin-bottom: 2px; }
  .medalist .medalist__type {
    background-image: -webkit-linear-gradient(0deg, #0392cf 0%, white 100%);
    padding: 10px 0;
    font-weight: 600; }
  .medalist .medalist__result {
    padding: 10px;
    box-sizing: border-box;
    flex-basis: 15%; }
  .medalist .medalist__gold, .medalist .medalist__silver, .medalist .medalist__bronze {
    width: 31px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 50%;
    margin: 0 auto; }
    .medalist .medalist__gold p, .medalist .medalist__silver p, .medalist .medalist__bronze p {
      line-height: 1.125rem;
      font-size: 1rem;
      font-weight: 600;
      padding: 3.5px 0; }
  .medalist .medalist__gold {
    border: 3px solid #dab509; }
  .medalist .medalist__silver {
    border: 3px solid #a1a1a1; }
  .medalist .medalist__bronze {
    border: 3px solid #ae7058; }
  .medalist .medalist__name {
    padding: 10px 0;
    flex-grow: 1; }
    .medalist .medalist__name a {
      color: #000;
      text-decoration: none; }
      .medalist .medalist__name a:hover {
        color: #94cde9; }
  .medalist .medalist__weight {
    padding: 10px;
    box-sizing: border-box;
    flex-basis: 25.5%; }

@media (max-width: 768px) {
  .medalist {
    font-size: 1rem;
    line-height: 1.4rem; } }

@media (max-width: 560px) {
  .medalist__wrap {
    min-width: 530px; } }
