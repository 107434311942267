.search {
  position: relative;

  .search__block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .search__input {
    position: absolute;
    left: -140px;
    top: 0;
    opacity: 0;
    pointer-events: none;
    transition-duration: 500ms;
  }

  .search__button {
    width: 48px;
    position: relative;
  }

  input {
    box-shadow: inset 1px 1px 1px rgba(0,0,0,.28);
    width: 141px;
    background-color: $white;
    font-size: 1rem;
    line-height: 1.625rem;
    color: #6a6565;
    padding: 0 0 1px 10px;
    box-sizing: border-box;
    border: 1px solid #9a9a9a;
  }

  button {
    box-shadow: none;
    border: none;
    background: none;
    margin: 0;
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    padding: 4.5px 0;
    cursor: pointer;

    svg {
      width: 19px;
      height: 19px;
      fill: $white;
      margin-right: 5px;
    }

  }

  .search__block--active {

    .search__input {
      opacity: 1;
      pointer-events: auto;
    }

    button {
      background: url('../img/button-bg.png') left no-repeat;
    }

  }

}

@media (max-width: 880px) {

  .search {

    input {
      padding: 2px 0 3px 10px;
    }

  }

}

@media (max-width: 768px) {

  .search {
    display: none;
  }

}