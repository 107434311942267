.map {
  background-color: $white;

  .map__title {
    font-size: 1.75rem;
    line-height: 2.125rem;
    font-weight: 600;
    padding: 0 30px 25px;
  }

}