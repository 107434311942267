.medal {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .medal__top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 15px 12px 25px;
  }

  .medal__title {
    color: $black;
    font-size: 1.75rem;
    font-weight: 600;
  }

  .medal__list {
    margin: 0 10px;
  }

  .medal__results {
    display: flex;
    flex-basis: 120px;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-shrink: 0;

    div {
      flex-basis: 31px;
      text-align: center;
      box-sizing: border-box;
      border-radius: 50%;

      &:first-child {
        border: 3px solid $gold;
      }

      &:nth-child(2) {
        border: 3px solid $silver;
      }

      &:last-child {
        border: 3px solid $bronze;
      }

      p {
        line-height: 1.125rem;
        font-size: 1rem;
        font-weight: 600;
        padding: 3.5px 0;
      }

    }

  }

  .medal__block {
    padding: 13px 15px 13px 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    box-sizing: border-box;

    &:nth-child(even) {
      background-color: #f5f5f5;
    }

  }

  .medal__type {
    flex-basis: calc(100% - 130px);

    p {
      font-size: 1rem;
      line-height: 1.125rem;
    }

  }

  .medal__results {
    flex-basis: 120px;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-shrink: 0;

      p {
        line-height: 1.125rem;
        font-size: 1rem;
        font-weight: 600;
        padding: 3.5px 0;
        flex-basis: 31px;
        text-align: center;
      }

  }

  .medal__button {

    a {
      @include btn();
    }

  }

}

@media (max-width: 980px) {

  .medal {

    .medal__title {
      font-size: 1.5rem;
    }

    .medal__list {
      margin-right: 0;
    }

    .medal__results {
      margin-left: auto;
      margin-top: 10px;
    }

  }

}

@media (max-width: 880px) {

  .medal {

    .medal__results {

      div {

        p {
          padding: 5px 0;
        }

      }

    }

  }

}