.date-share {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -10px 0 15px;

  .date-share__date {
    color: #8b8a8a;
    font-size: 1rem;
    line-height: 1.375rem;
    margin: 5px 45px 5px 0;
  }

}

@media (max-width: 480px) {

  .date-share {
    flex-direction: column;
    align-items: flex-start;

    .date-share__date {
      margin-right: 0;
    }

  }

}