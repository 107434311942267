.centre {

  .breadcrumbs {
    margin-top: -5px;
  }

  .centre__block {
    display: flex;
    flex-wrap: wrap;
  }

  .centre__element {
    flex-basis: 31%;
    margin: 0 3.5% 30px 0;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    .centre__img {
      position: relative;
      overflow: hidden;

      a {
        display: block;

        &:hover {

          img {
            filter: brightness(115%);
            transform: scale(1.1);
          }

        }

      }

      img {
        width: 100%;
        display: block;
        transition-property: filter, transform;
        transition-duration: 500ms;
      }

      p {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 1rem;
        line-height: 1.375rem;
        color: $white;
        background-color: $black;
        padding: 2px 15px;
      }

    }

    .centre__link {
      font-size: 1rem;
      line-height: 1.375rem;
      color: #8b8a8a;
      margin: 10px 0;

      a {
        text-decoration: none;
        font-weight: 600;
        color: $black;

        &:hover {
          color: $sub-menu-blue;
        }

      }

    }

    .centre__tags {
      margin: 10px 0;

      a {
        margin-right: 5px;

        &:last-child {
          margin-right: 0;
        }

      }

    }

  }

  .centre__btn {
    margin: 0 0 20px;

    a {

      @include btn() {
        font-size: 1rem;
        line-height: 1.375rem;
        font-weight: 400;
        padding: 11px 0;
      };

      .centre__arr {
        transform: rotate(90deg);
        margin-left: 5px;
      }

    }

  }

}

@media (max-width: 640px) {

  .centre {

    .centre__element {
      flex-basis: 48%;

      &:nth-child(3n + 3) {
        margin-right: 3.5%;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }

    }

  }

}

@media (max-width: 480px) {

  .centre {

    .centre__block {
      flex-direction: column;
    }

    .centre__element {
      flex-basis: auto;
      margin-bottom: 20px;
      margin-right: 0;

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      .centre__img {

        img {
          width: 100%;
        }

      }

    }

  }

}