@keyframes popups {
  0% {
    transform: translate(-50%, -2000px);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}

.popup {
  max-width: 1110px;
  max-height: 570px;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  background-color: $white;
  border-top: 5px solid $blue;
  text-align: center;
  display: none;
  padding: 0;
  box-sizing: border-box;

  .popup__title {
    font-size: 1.75rem;
    line-height: 2rem;
    margin: 25px 0 35px;
    font-weight: 600;
    padding-left: 50px;
    padding-right: 50px;
  }

  .popup__legend {
    position: absolute;
    right: 122px;
    top: 110px;
    text-align: left;
  }

  .popup__yes {
    font-size: 1.125rem;
    line-height: 1.4rem;
    padding-left: 27px;
    position: relative;
    margin: 5px 0;

    &::before {
      content: "";
      width: 14px;
      height: 13px;
      display: block;
      background-color: rgb(97, 191, 26);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(calc(-50% + 1px));
    }

  }

  .popup__no {
    font-size: 1.125rem;
    line-height: 1.4rem;
    padding-left: 27px;
    position: relative;
    margin: 5px 0;

    &::before {
      background-color: rgb(31, 170, 229);
      content: "";
      width: 14px;
      height: 13px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(calc(-50% + 1px));
    }

  }

  .popup__close {
    appearance: none;
    border: none;
    box-shadow: none;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background-color: transparent;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  .popup__ok {
    @include btn();
    border: none;
    appearance: none;
    width: 190px;
    cursor: pointer;
    margin: 30px auto;
  }

}

.popup--active {
  display: block;
  animation-name: popups;
  animation-duration: 500ms;
}

@media (max-width: 768px) {

  .popup {
    max-width: 100%;
    width: calc(100% - 30px);

    .popup__legend {
      position: static;
      text-align: center;
      margin-bottom: 20px;
    }

    .popup__title {
      margin-bottom: 20px;
    }

    .popup__no {
      display: inline;
    }

    .popup__yes {
      display: inline;
      margin-right: 15px;
    }

  }

}

@media (max-width: 640px) {

  .popup {

    .popup__title {
      font-size: 1.5rem;
    }

    .popup__legend {
      right: 30px;
      top: 90px;
    }

  }

}