.full-img {
  margin: 30px 0;

  img {
    max-width: 100%;
    display: block;
  }

  p {
    color: #8b8a8a;
    margin: 10px 0 0 !important;
  }

}