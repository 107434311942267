.img {
  float: left;
  margin: 10px 30px 0 0;
  max-width: 44%;

  img {
    display: block;
    max-width: 100%;
  }

}

@media (max-width: 480px) {

  .img {
    float: none;
    max-width: 100%;
    margin-right: 0;

    img {
      margin: 0 auto;
    }

  }

}