.federacia {

  .breadcrumbs {
    margin-top: -5px;
  }

  .federacia__title {
    font-size: 1.75rem;
    line-height: 2.2rem;
    font-weight: 600;
    color: $black;
    margin: 10px 0;
  }

  .federacia__block {
    background-color: $white;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 15px 30px;
    box-sizing: border-box;
    margin: 0 0 50px;
    box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  }

  .federacia__elements {
    display: flex;
    //justify-content: space-between;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .federacia__sub-title {
    flex-basis: 100%;
    font-size: 1.5rem;
    line-height: 1.875rem;
    font-weight: 600;
    margin-top: 10px;
  }

  .federacia__element {
    flex-basis: 31%;
    margin: 12px 3.5% 12px 0;
    align-self: flex-end;

    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }

    img {
      display: block;
      width: 100%;
    }

    p {
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.875rem;
      margin: 10px 0;

      span {
        font-weight: 400;
        display: block;
      }

    }

  }

  .federacia__block--one {
    display: flex;
    flex-direction: column;
    width: 37%;

    .federacia__element {
      flex-basis: 100%;
      margin-right: 0;

      &:first-child {
        margin-right: 0;
      }

    }

  }

}

@media (max-width: 768px) {

  .federacia {

    .federacia__block {
      padding-left: 20px;
      padding-right: 20px;
    }

  }

}

@media (max-width: 480px) {

  .federacia {

    .federacia__element {
      flex-basis: 100%;

      &:nth-of-type(1n + 1) {
        margin-right: 0;
      }

    }

    .federacia__block--one {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

  }

}