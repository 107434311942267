.news-front {
  background-color: $white;
  padding-bottom: 10px;
  height: 100%;

  .news-front__block {
    margin: 17px 0;
    padding: 0 35px;

    &:nth-child(2){
      padding-top: 5px;
    }

    p {
      color: #8b8a8a;
      font-size: 1rem;
      line-height: 1.5625rem;
      margin: 5px 0;

      a {
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: $black;
        font-weight: 400;
        text-decoration: none;
        display: inline;
        margin-left: 5px;

        &:hover {
          color: $sub-menu-blue;
        }

      }

    }

  }

  .news-front__block--first {
    padding: 0;
    margin: 0;
    position: relative;

    > a {
      position: relative;
      display: block;
      overflow: hidden;

      &:hover {

        span {
          color: $sub-menu-blue;
        }

        img {
          filter: brightness(110%);
          transform: scale(1.1);
          box-shadow: 2px 3px 3px rgba(0,0,0,.35);
        }

      }
      
    }

    img {
      display: block;
      max-width: 100%;
      box-shadow: 1px 2px 2px rgba(0,0,0,.35);
      transition-duration: 500ms;
      transition-property: filter, transform;
    }

    p {
      color: $white;
      font-size: 1.125rem;
      line-height: 1.5625rem;

      span {
        font-size: 1.375rem;
        line-height: 1.75rem;
        color: $white;
        font-weight: 600;
        text-decoration: none;
        display: inline;
        margin-left: 5px;

      }

    }

  }

  .news-front__text {
    padding: 25px 35px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    background-image: linear-gradient(to top, #000, rgba(0,0,0,0));
  }

  .news-front__online {
    position: absolute;
    left: -10px;
    top: 30px;
    clip-path: polygon(0% 0%, 92% 0%, 100% 50%, 92% 100%, 0% 100%);
    padding: 5px 0;

    a {
      box-shadow: 1px 2px 2px rgba(0,0,0,0.35);
      background-color: $red;
      width: 270px;
      display: block;
      font-size: 1.25rem;
      line-height: 1.25rem;
      padding: 13px 0;
      color: $white;
      text-decoration: none;
      font-weight: 600;
      text-align: center;

      &:hover {
        box-shadow: 2px 3px 3px rgba(0,0,0,.35);
      }

    }

    .quote {
      font-size: 1rem;
      line-height: 1.875rem;
    }

  }

}

@media (max-width: 860px) {

  .news-front {

    .news-front__block--first {

      img {
        width: 100%;
      }

    }

  }

}

@media (max-width: 480px) {

  .news-front {

   .news-front__block {
     padding: 0 15px;

     p {
       font-size: 1rem;
       line-height: 1.2rem;

       span {
         font-size: 1rem;
         line-height: 1.2rem;
       }

       a {
         font-size: 1rem;
         line-height: 1.2rem;
       }

     }

   }

    .news-front__block--first {
      padding: 0;

      p {
        font-size: 1rem;
        line-height: 1.2rem;

        span {
          font-size: 1rem;
          line-height: 1.2rem;
        }


        a {
          font-size: 1rem;
          line-height: 1.2rem;
        }

      }

      .news-front__text {
        bottom: 0;
        padding: 5px 15px;
      }

    }

    .news-front__online {
      padding: 10px 0;
      top: 15px;
    }

  }

}