.footer {
  background-color: $blue;
  position: relative;
  border-top: 1px solid $black;
  padding-bottom: 15px;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: linear-gradient(to top, rgba(0,0,0,.52), rgba(0,0,0,0));
  }

  .footer__menu {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;

    ul {
      margin-right: 50px;
      padding: 15px 0;
      position: relative;

      &:hover {

        &::before {
          content: "";
          height: 3px;
          width: 100%;
          background-color: $black;
          display: block;
          margin: 0 auto;
          position: absolute;
          left: 0;
          top: 0;
        }

      }


      &:last-child {
        margin-right: 0;
      }

      li {

        &:first-child {
          margin-bottom: 7px;

          a {
            font-size: 1.5rem;
            line-height: 2.1875rem;
          }

        }

        a {
          color: $white;
          font-size: 1.125rem;
          line-height: 1.75rem;
          font-weight: 600;
          text-decoration: none;

          &:hover {
            color: $sub-menu-blue;
          }

        }

      }

    }

  }

  .footer__bottom {
    position: relative;
    z-index: 1;
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .footer__left {
    flex-basis: 480px;
    box-sizing: border-box;
    display: flex;

    .footer__logo {
      flex-basis:  57px;
      flex-shrink: 0;
      margin-right: 12px;
      margin-top: 12px;

      img {
        max-width: 100%;
        display: block;
      }

    }

    p {
      margin: 15px 0;
      line-height: 1.4375rem;
      color: $white;
    }

  }

  .footer__right {
    
    .footer__phone {
      text-align: right;
      margin: 15px 0;

      a {
        background: url('../img/mobile-phone.png') left center no-repeat;
        padding-left: 30px;
        font-weight: 800;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: $white;
        text-decoration: none;
      }

    }

    .footer__socials {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 30px 0;

      p {
        font-size: 1rem;
        line-height: 1.4375rem;
        color: $white;
      }

      a {
        margin: 0 5px;

        &:first-of-type {
          margin-left: 15px;
        }

        img {
          display: block;
          width: 32px;
        }

      }

    }

  }

}

@media (max-width: 880px) {

  .footer {

    .footer__menu {

      ul {
        margin-right: 25px;

        li {

          &:first-child {

            a {
              font-size: 1.3rem;
            }

          }

          a {
            font-size: 1.125rem;
          }

        }

      }

    }

  }

}

@media (max-width: 768px) {
  
  .footer {
    
    .footer__menu {
      display: none;
    }

    .footer__bottom {
      flex-direction: column;
      margin: 10px 0;

      .footer__left {
        flex-basis: auto;
        align-self: center;
        order: 2;
      }

      .footer__right {
        flex-basis: auto;
        align-self: center;
        order: 1;
      }

      .footer__phone {
        text-align: center;
      }

      .footer__socials {
        margin: 15px 0;
      }

    }
    
  }
  
}