.calendar {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .calendar__top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 15px 12px 25px;
  }

  .calendar__title {
    color: $black;
    font-size: 1.75rem;
    font-weight: 600;
  }

  .calendar__list {
    margin: 0 0 0 10px;
  }


  .calendar__block {
    padding: 13px 15px 13px 25px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    &:nth-child(even) {
      background-color: #f5f5f5;
    }

  }

  .calendar__type {

    p {
      font-size: 1rem;
      line-height: 1.125rem;
      color: $blue;
      padding-right: 25px;
      position: relative;
    }
    
    a {
      color: $black;
      text-decoration: none;

      &:hover {
        color: $sub-menu-blue;
      }

      .quote {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

    }

  }


  .calendar__button {

    a {
      @include btn();
    }

  }

}

@media (max-width: 980px) {

  .calendar {

    .calendar__title {
      font-size: 1.5rem;
    }

  }

}

@media (max-width: 768px) {

  .calendar {
    display: block;
    height: auto;
  }

}

@media (max-width: 480px) {

  .calendar {

    .calendar__list {
      margin-left: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }

  }

}