.sportsmen {

  .breadcrumbs {
    margin-top: -5px;
  }

  .sportsmen__block {
    display: flex;
    margin: 30px 0;
    flex-wrap: wrap;
  }

  .sportsmen__element {
    flex-basis: 31%;
    margin: 10px 3.5% 25px 0;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    img {
      display: block;
      width: 100%;
      transition-property: filter, transform;
      transition-duration: 500ms;
    }

    a {
      color: $black;
      text-decoration: none;
      display: block;

      .sportsmen__img {
        overflow: hidden;
      }

      .sportsmen__name {
        font-size: 1.375rem;
        line-height: 1.75rem;
        font-weight: 600;
        display: block;
        margin-top: 10px;
      }

      &:hover {
        color: $sub-menu-blue;

        img {
          filter: brightness(115%);
          transform: scale(1.1);
        }

      }

    }

    p {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }


  }

}

@media (max-width: 560px) {

  .sportsmen {

    .sportsmen__element {
      flex-basis: 100%;
      margin-right: 0;

      &:last-child {
        margin-bottom: 0;
      }

    }

  }

}