.competition {

  .breadcrumbs {
    margin-top: -5px;
  }

  .competition__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    .competiton__left {
      flex-basis: 380px;
    }

    .competition__right {
      flex-basis: 42%;
    }

    .competition__city {
      font-size: 1rem;
      line-height: 1.375rem;
      background: url('../img/flag.png') left 5px no-repeat;
      padding: 5px 0 15px 35px;
      margin: 20px 0;
    }

    .competition__member {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      div {
        flex-basis: 30%;

        p {
          font-size: 3.75rem;
          font-weight: 700;
        }

        span {
          font-size: 1rem;
          line-height: 1.375rem;
          font-weight: 400;
          display: block;
        }

      }

    }

    .competition__judge {
      border: 1px solid #949494;
      padding: 5px 15px 5px 35px;
      box-sizing: border-box;

      p {
        font-size: 1rem;
        line-height: 1.5rem;
        color: #8b8a8a;
        margin: 15px 0;
      }

      span {
        color: $black;
        display: block;
      }

    }

  }

  .competition__position {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 15px 0 10px;

    div {
      flex-basis: 220px;
      margin: 10px 20px 10px 0;

      &:last-child {
        margin-right: 0;
      }

      a {
        color: $black;
        text-decoration: none;
        font-size: 1rem;
        line-height: 1.625rem;
        display: inline-block;
        padding-left: 40px;
        box-sizing: border-box;
        background: url('../img/pdf.png') left center no-repeat;

        span {
          border-bottom: 1px solid $blue;
        }

        &:hover {

          span {
            border-bottom-color: transparent;
          }

        }

      }

    }

  }

  .competition__result {
    margin: 50px 0 15px;
  }

  .competition__title {
    font-weight: 600;
    font-size: 1.75rem;
    line-height: 2.125rem;
    margin: 15px 0 0;
  }

  .competition__top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .competition__sort {
    flex-basis: 440px;
    margin-top: 15px;
  }

  .competition__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 5px 0;
  }

  .competition__element {
    background-color: $white;
    box-shadow: 1px 1px 1px rgba(0,0,0,.15);
    flex-basis: 49%;
    padding: 20px 25px 25px 30px;
    box-sizing: border-box;
    margin-bottom: 15px;

    &:nth-child(odd) {
      margin-right: 2%;
    }

    .competition__type {
      font-weight: 600;
      font-size: 1.5rem;
      margin: 10px 0 30px;
    }

    .competition__gold, .competition__silver, .competition__bronze {
      font-size: 1.125rem;
      line-height: 1.375rem;
      display: flex;
      align-items: center;
      margin: 20px 0;

      a {
        text-decoration: none;
        color: $black;

        &:hover {
          color: $blue;
        }

      }

      span {
        flex-basis: 31px;
        flex-shrink: 0;
        border: 3px solid $gold;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        line-height: 1.125rem;
        font-size: 1rem;
        font-weight: 600;
        box-sizing: border-box;
        padding: 3.5px 0;
        margin-right: 10px;
      }

    }

    .competition__silver {

      span {
        border-color: $silver;
      }

    }

    .competition__bronze {

      span {
        border-color: $bronze;
      }

    }

  }

}

@media (max-width: 1024px) {

  .competition {

     .competition__info {

       .competiton__left {
         flex-basis: 100%;
       }

       .competition__right {
         flex-basis: 100%;
       }

     }

    .competition__top {
      margin-bottom: 20px;
    }

    .competition__sort {
      flex-basis: 100%;
    }

  }

}

@media (max-width: 860px) {

  .competition {

    .competition__element {
      padding: 15px;

      .competition__gold, .competition__silver, .competition__bronze {

        span {
          padding: 5px 0;
        }

      }


    }

  }

}

@media (max-width: 768px) {

  .competition {

    .competition__position {

      div {
        flex-basis: 100%;
      }

    }

  }

}

@media (max-width: 560px) {

  .competition {

    .competition__element {
      flex-basis: 100%;

      &:nth-child(odd) {
        margin-right: 0;
      }

    }

  }

}