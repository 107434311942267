@mixin clearfix() {
	&:before,
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin min-max($min-width, $max-width, $padding) {
	min-width: $min-width;
	max-width: $max-width;
	padding: 0 $padding;
	box-sizing: border-box;
	margin: 0 auto;
}

@mixin btn() {
	box-shadow: 1px 2px 2px rgba(0,0,0,.35);
	background-color: $blue;
	font-weight: 600;
	font-size: 1.25rem;
	line-height: 1.5625rem;
	text-align: center;
	color: $white;
	text-decoration: none;
	padding: 16px 0;
	display: block;
	@content;

	.quote {
		transition-duration: 200ms;
		transition-property: transform;
	}

	&:hover {
		background-color: darken($blue, 10%);
		box-shadow: 2px 3px 3px rgba(0,0,0,.35);

		.quote {
			transform: translateX(5px);
		}

	}


}