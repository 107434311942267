.toss {

  .breadcrumbs {
    margin-top: -5px;
  }

  .toss__top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .toss__sort {
    flex-basis: 440px;
    margin-top: 15px;
  }

  .toss__element {
    margin: 0 0 50px 0;

    .toss__title {
      font-size: 1.75rem;
      line-height: 2.125rem;
      font-weight: 600;
      margin: 20px 0;
    }

  }

  .toss__table {
    display: flex;
    flex: 1;
    padding-right: 70px;
    box-sizing: border-box;

    .toss__column {
      display: flex;
      flex-direction: column;
      max-width: 145px;
      min-width: 66px;
      flex: 1;

      p {
        font-size: 0.875rem;
        color: $green;
        line-height: 1.125rem;
        position: absolute;
        bottom: -25px;
        max-width: 115px;
      }

      &:first-child {

        .toss__man {
          height: 123px;
        }

      }

      &:nth-child(2) {

        .toss__man {
          margin: 27px 0 26px 0;
          height: 193px;
          transform: translateX(49px);

          a {

            &:last-child {
              margin-top: 87px;

              &::after {
                content: "";
                width: 25px;
                height: 1px;
                border-bottom: 1px solid $blue;
                position: absolute;
                right: -25px;
                top: 50%;
                transform: translateY(-50%);
              }

            }

            &:first-child {

              &::after {
                content: "";
                width: 25px;
                height: 1px;
                border-bottom: 1px solid $blue;
                position: absolute;
                right: -25px;
                top: 50%;
                transform: translateY(-50%);
              }

              &::before {
                height: 124px;
              }

            }

          }

        }

      }

      &:nth-child(3) {

        .toss__man {
          margin: 89px 0 89px 0px;
          height: 316px;
          transform: translateX(98px);

          a {

            &:last-child {
              margin-top: 210px;

              &::after {
                content: "";
                width: 25px;
                height: 1px;
                border-bottom: 1px solid $blue;
                position: absolute;
                right: -25px;
                top: 50%;
                transform: translateY(-50%);
              }

            }

            &::before {
              height: 247px;
            }

          }

        }

      }

      &:nth-child(4) {

        .toss__man {
          margin: 212px 0 212px 0px;
          height: 565px;
          transform: translateX(147px);

          &::after {
            right: -24px;
            width: 70px;
          }

          a {

            &:last-child {
              margin-top: 459px;

              &::after {
                content: "";
                width: 25px;
                height: 1px;
                border-bottom: 1px solid $blue;
                position: absolute;
                right: -25px;
                top: 50%;
                transform: translateY(-50%);
              }

            }

            &::before {
              height: 496px;
            }

          }

        }

      }

      &:nth-child(5) {
        transform: translateX(1px);

        .toss__man {
          margin: 478px 0 0 0;
          height: 53px;

          &::after {
            display: none;
          }

          a {
            margin-bottom: 0;

            &:last-child, &:first-child {
              margin-top: 0;

              &::after {
                display: none;
              }

            }

            &::before {
              display: none;
            }

          }

        }

      }

    }

    .toss__man {
      position: relative;

      &::after {
        content: "";
        width: 25px;
        height: 1px;
        border-bottom: 1px solid $blue;
        position: absolute;
        right: -49px;
        top: 50%;
      }

      a {
        border: 1px solid $blue;
        font-size: 1rem;
        line-height: 1.125rem;
        display: block;
        text-align: center;
        color: $black;
        text-decoration: none;
        padding: 8px 0;
        background-color: $white;
        margin: 17px 0;
        white-space: nowrap;
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        left: 0;
        height: 36px;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }

        &:first-child {
          top: 0;

          &::after {
            content: "";
            width: 25px;
            height: 1px;
            border-bottom: 1px solid $blue;
            position: absolute;
            right: -25px;
            top: 50%;
            transform: translateY(-50%);
          }

          &::before {
            content: "";
            width: 1px;
            height: 54px;
            border-left: 1px solid $blue;
            position: absolute;
            right: -26px;
            top: 50%;
          }

        }

        &:last-child {
          bottom: 0;

          &::after {
            content: "";
            width: 25px;
            height: 1px;
            border-bottom: 1px solid $blue;
            position: absolute;
            right: -25px;
            top: 50%;
            transform: translateY(-50%);
          }

        }

        &:hover {
          color: $blue;
        }

      }

      .toss__winner {
        color: $green;
      }

    }

  }

  .toss__table--four {

    .toss__column {

      &:nth-child(4) {

        .toss__man {
          height: 70px;

          &::after {
            display: none;
          }

          a {

            &:last-child {
              margin-top: 459px;

              &::after {
                display: none;
              }

            }

            &::before, &::after {
              display: none;
            }

          }

        }

      }

    }

  }

  .toss__table--three {

    .toss__column {

      &:nth-child(3) {

        .toss__man {
          height: 70px;

          &::after {
            display: none;
          }

          a {

            &:last-child {
              margin-top: 459px;

              &::after {
                display: none;
              }

            }

            &::before, &::after {
              display: none;
            }

          }

        }

      }

    }

  }

  .toss__table--two {

    .toss__column {

      &:nth-child(2) {

        .toss__man {
          height: 70px;

          &::after {
            display: none;
          }

          a {

            &:last-child {
              margin-top: 459px;

              &::after {
                display: none;
              }

            }

            &::before, &::after {
              display: none;
            }

          }

        }

      }

    }

  }

}

@media (max-width: 1024px) {

  .toss {

    .toss__top {
      margin-bottom: 20px;
    }

    .toss__sort {
      flex-basis: 100%;
    }

  }

}

@media (max-width: 768px) {

  .toss {

    .toss__table {

      .toss__column {

        &:nth-child(5) {
          transform: translateX(30px);
        }

      }

    }

  }

}

@media (max-width: 640px) {

  .toss {

    .toss__table {
      padding-right: 0;

      .toss__column {

        &:nth-child(2) {

          .toss__man {
            transform: translateX(5px);
          }

        }

        &:nth-child(3) {

          .toss__man {
            transform: translateX(10px);
          }

        }

        &:nth-child(4) {

          .toss__man {
            transform: translateX(15px);
          }

        }

        &:nth-child(5) {
          transform: translateX(-100%);
        }

      }

      .toss__man {

        p {
          font-size: 0.7rem;
          bottom: -12px;
        }
        
        &::after {
          //display: none;
        }
        
        a {
          font-size: 0.8rem;
          
          &:first-child, &:last-child {
            
            &::after {
              //display: none;
            }
            
            &::before {
              //display: none;
            }
            
          }
          
        }
        
      }
      
    }

    .toss__table--four {

      .toss__column {

        &:nth-child(4) {

          .toss__man {
            transform: translateX(40px);
          }

        }

      }

    }

    .toss__table--three {

      .toss__column {

        &:nth-child(3) {

          .toss__man {
            transform: translateX(40px);
          }

        }

      }

    }

    .toss__table--two {

      .toss__column {

        &:nth-child(2) {

          .toss__man {
            transform: translateX(40px);
          }

        }

      }

    }

  }

}