.birthday {

  .birthday__title {
    font-size: 1.75rem;
    line-height: 1.875rem;
    font-weight: 600;
    margin: 0 0 25px;
  }

  .birthday__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .birthday__element {
    display: flex;
    flex-basis: 48%;
    margin-bottom: 32px;
  }

  .birthday__img {
    flex-basis: 130px;
    flex-shrink: 0;
    margin-right: 20px;

    img {
      display: block;
      max-width: 100%;
    }

  }

  .birthday__info {

    p {
      font-size: 1.125rem;
      line-height: 1.875rem;
      margin: 5px 0;

      span {
        color: $blue;
      }

    }

    .birthday__name {
      font-size: 1.25rem;
      line-height: 1.5625rem;
      font-weight: 600;
      margin-top: -3px;
      text-decoration: none;
      color: $black;

      &:hover {
        color: $sub-menu-blue;
      }

    }

  }

  .birthday__all {
    @include btn() {
      max-width: 451px;
      margin-top: 5px;
    };
  }

}

@media (max-width: 980px) {

  .birthday {

    .birthday__list {
      flex-direction: column;
    }

    .birthday__element {
      flex-basis: auto;
    }

  }

}

@media (max-width: 768px) {

  .birthday {

    .birthday__list {
      flex-direction: row;
    }

    .birthday__element {
      flex-basis: 48%;
    }

  }

}

@media (max-width: 580px) {

  .birthday {

    .birthday__list {
      flex-direction: column;
    }

    .birthday__element {
      flex-basis: auto;
    }

  }

}