.logo {
  display: flex;
  align-items: center;
  flex-basis: 330px;
  flex-shrink: 0;

  > a {
    display: flex;
    align-items: center;
    flex-basis: 330px;
    flex-shrink: 0;
    text-decoration: none;
  }

  .logo__img {

    a {
      display: block;
    }

  }

  .logo__text {
    margin-left: 15px;

    p {
      font-size: 1.875rem;
      line-height: 1.6875rem;
      font-family: 'Fira Sans Condensed';
      font-weight: 500;
      color: $white;
    }

    span {
      font-size: 1.3125rem;
      display: block;
    }

  }

}

@media (max-width: 480px) {

  .logo {
    flex-basis: 230px;

    .logo__img {

      img {
        display: block;
        max-width: 40px;
      }

    }

    .logo__text {
      margin-right: 0;

      p {
        font-size: 1.57rem;
        line-height: 1.42;

        span {
          font-size: 1.2rem;
        }

      }

    }

  }

}