.breadcrumbs {
  margin: 15px 0;

  p {

  }

  .breadcrumbs__arrow {
    margin: 0 5px;
  }

  a {
    font-size: 1rem;
    line-height: 1.875rem;
    display: inline-block;
    color: $black;
    text-decoration: none;

    &:hover {
      color: $sub-menu-blue;
    }

  }

  span {
    color: #8b8a8a;
  }

}