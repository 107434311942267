.video-full {
  margin: 35px 0;

  .video-full__title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin: 15px 0;
  }

  .video-full__player {
    position: relative;
    padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin: 15px 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }

  p {
    font-size: 1rem;
    line-height: 1.625rem;
    margin: 15px 0;
  }

}