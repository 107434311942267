.service {
  background-color: $white;
  padding: 5px 30px 20px 30px;

  .service__phone {
    margin: 12px 0;
    padding-left: 50px;
    box-sizing: border-box;
    background: url('../img/mobile-phone.svg') left center no-repeat;
    background-size: 20px;

    a {
      font-size: 1.5rem;
      line-height: 2.9375rem;
      color: $black;
      font-weight: 600;
      text-decoration: none;


      &:hover {
        color: $sub-menu-blue;
      }

    }

    span {
      color: #8b8a8a;
      font-size: 1.125rem;
    }

  }

  .service__mail {
    margin: 12px 0;

    a {
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: $black;
      text-decoration: none;
      background: url('../img/envelope.svg') left center no-repeat;
      background-size: 25px;
      padding-left: 50px;

      span {
        border-bottom: 1px solid $blue;
      }


      &:hover {
        color: $sub-menu-blue;

        span {
          border-bottom-color: transparent;
        }

      }

    }

  }

  .service__address {
    margin: 20px 0;

    p {
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: $black;
      text-decoration: none;
      background: url('../img/placeholder.svg') left center no-repeat;
      background-size: 20px;
      padding-left: 50px;
    }

    span {
      color: #8b8a8a;
      font-size: 1.125rem;
    }

  }

  .service__block {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
  }

  .service__element {
    flex-basis: 31%;
    margin: 10px 3.5% 10px 0;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    img {
      display: block;
      max-width: 100%;
      transition-property: filter, transform;
      transition-duration: 500ms;
    }

    a {
      color: $black;
      text-decoration: none;
      display: block;

      .service__img {
        overflow: hidden;
      }
      
      .service__name {
        font-size: 1.375rem;
        line-height: 1.75rem;
        font-weight: 600;
        display: block;
        margin-top: 10px;
      }

      &:hover {
        color: $sub-menu-blue;

        img {
          filter: brightness(115%);
          transform: scale(1.1);
        }

      }

    }

    p {
      font-size: 1.125rem;
      line-height: 1.75rem;

      a {
        border-bottom: 1px solid $blue;
        display: inline-block;

        &:hover {
          border-bottom-color: transparent;
        }

      }

    }


  }

}

@media (max-width: 768px) {

  .service {
    padding-left: 20px;
    padding-right: 20px;
  }

}

@media (max-width: 560px) {

  .service {

    .service__block {
      flex-direction: column;
    }

    .service__element {
      flex-basis: auto;
      margin-bottom: 20px;
      margin-right: 0;

      img {
        width: 100%;
      }

    }

  }

}