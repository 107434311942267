.content {
  background-color: $gray;
  padding: 15px 0;

  .content__left {
    flex-basis: 255px;
  }

  .content__right {
    flex-basis: calc(100% - 30px - 255px);
    box-sizing: border-box;
  }

  .content__right--full {
    flex-basis: 100%;
  }

  .content__wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  h1 {
    font-size: 2.25rem;
    line-height: 2.8125rem;
    font-weight: 700;
    margin: 15px 0 20px;
  }

}

@media (max-width: 860px) {

  .content {

    .content__wrap {
      flex-direction: column;
    }

    .content__left {
      flex-basis: auto;
      margin-bottom: 20px;
    }

    .content__right {
      flex-basis: auto;
    }

  }

}

@media (max-width: 768px) {

  .content {

    h1 {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

  }

}